import API from 'api';

const get = (id) => API.get(`/professor/${id}`);

const list = (course) => API.get(`/curso/${course}/professores`);

export default {
  get,
  list,
};
