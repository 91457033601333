import Styled from 'styled-components';
import { Form } from 'formik';
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import Text from 'components/Text';

export const Container = Styled(Grid)`
  margin-bottom: 30px;
  width: 100%;

  @media(max-width: 965px) {
    margin-bottom: 0px;
    width: 100%;
  }
`;

export const BtnBorder = Styled(Button)`
  border: 1px solid ${({ theme }) => theme.green.primary};
  border-radius: 8px;
`;

export const PartnerList = Styled(Text).attrs(() => ({
  style: {
    width: '100%',
    cursor: 'pointer',
    textDecoration: 'underline',
  }
}))``;

export const FormikForm = Styled(Form)`
  margin-top: 25px;
  width: 100%;
`;

export const Title = Styled(Text)`
  font-size: 32px;

  @media (max-width: 700px) {
    font-size: 18px;
  }
`;

