import React from 'react';

import { Grid } from '@material-ui/core';
import Carousel from './components/Carousel';

import Theme from 'style/Theme';
import * as Styled from './styles';

const BlogLastestPosts = () => (
  <Styled.Container
    container
    direction="row"
    alignItems="center"
    justify="center"
  >
    <Grid item xs={12} sm={12} md={4}>
      <Grid
        container
        justify="center"
      >
        <Styled.Title>
          Postagens recentes do nosso&nbsp;
          <span style={{ color: Theme.green.primary }}>
            blog
          </span>
        </Styled.Title>
      </Grid>
    </Grid>

    <Grid container item xs={12} sm={12} md={6}>
      <Grid container item>
        <Carousel />
      </Grid>
    </Grid>
  </Styled.Container>
);

export default BlogLastestPosts;
