import React from 'react';
import { useStoreState } from 'easy-peasy';

import InsertCodeDialog from 'components/InsertCodeDialog';
import BackgroundVideo from './components/BackgroundVideo';
import PandemiaDialog from './components/PandemiaDialog';

import * as Styled from './styles';
import Theme from 'style/Theme';
import { Hidden } from '@material-ui/core';

const Home = () => {
  const [
    configs,
  ] = useStoreState(({ main }) => [
    main.configs,
    main.loading,
  ]);

  const [open, setOpen] = React.useState(false);
  const [openInsertCode, setOpenInsertCode] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenInsertCode = () => {
    setOpenInsertCode(true);
  };

  const handleCloseInsertCode = () => {
    setOpenInsertCode(false);
  };

  return (
    <div>
      <InsertCodeDialog
        open={openInsertCode}
        onClose={handleCloseInsertCode}
      />
      <PandemiaDialog open={open} onClose={handleClose} configs={configs} />

      <Styled.Container
        container
        direction="column"
        alignItems="center"
        justify="space-between"
      >
        <Styled.TitleContainer container justify="center" wrap="wrap">
          <Styled.Title align="center">
            Conheça a UNIESSA, eleita
            <span style={{ color: Theme.green.primary }}>
              {` 3x a melhor `}
              <Hidden smDown>
                <br/>
              </Hidden>
              faculdade particular
            </span>
            {` de Uberlândia pelo MEC`}
          </Styled.Title>
        </Styled.TitleContainer>

        <BackgroundVideo />

        <Styled.ButtonContainer
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Styled.ButtonAlreadyHaveCode onClick={handleOpenInsertCode}>
            <span>
              Já tem um código e quer fazer seu vestibular? <br/>Clique aqui.
            </span>
          </Styled.ButtonAlreadyHaveCode>
          
          {(configs?.BOTAO_PANDEMIA === true || configs?.BOTAO_PANDEMIA === 'true') && (
            <Styled.ButtonPandemia onClick={handleOpen}>
              Clique e saiba como estamos funcionando <br/>na pandemia de Covid-19.
            </Styled.ButtonPandemia>
          )}
        </Styled.ButtonContainer>
      </Styled.Container>
    </div>
  );
}

export default Home;
