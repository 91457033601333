import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik  } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { CircularProgress, Grid, InputAdornment } from '@material-ui/core';
import Text from 'components/Text';
import ErrorText from 'components/ErrorText';
import Button from 'components/Button';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

import Theme from 'style/Theme';
import * as Styled from './styles';

const phoneRegExp = /^\(\d{2}\)?\s*\d{5}\-?\d{4}$/g;

const registerSchema = Yup.object().shape({
  name: Yup
    .string('Informe seu nome')
    .required('Informe seu nome'),
  birthDate: Yup
    .string('Informe sua data de nascimento')
    .required('Informe sua data de nascimento'),
  email: Yup
    .string('Informe seu e-mail')
    .email('Digite um e-mail válido')
    .required('Informe seu e-mail'),
  phone: Yup
    .string('Digite seu celular')
    .test(
      'phoneInvalid',
      'Telefone inválido',
      (value) => {
        return value?.replace(/\s|\(|\(|\)|-/gi, '').length === 11 && value?.replace(/\s/gi, '').length === 14
      }
    )
    .required('Informe seu celular'),
  course: Yup
    .string('Selecione o seu curso')
    .required('Selecione o seu curso'),
  publicSchool: Yup
    .string('Selecione uma opção')
    .required('Selecione uma opção'),
  madeEnem: Yup
    .string('Selecione uma opção')
    .required('Selecione uma opção'),
  noteEnem: Yup
    .string('Informe sua nota do ENEM'),
  hasGraduation: Yup
    .string('Selecione uma opção')
    .required('Selecione uma opção'),
  isPartner: Yup
    .string('Selecione uma opção')
    .required('Selecione uma opção'),
  income: Yup
    .string('Informe sua renda familiar mensal')
    .required('Informe sua renda familiar mensal'),
  familyMembers: Yup
    .string('Informe quantas pessoas vivem com você')
    .required('Informe quantas pessoas vivem com você'),
});

const OPTIONS = [
  { label: 'SIM', value: 'SIM' },
  { label: 'NÃO', value: 'NAO' }
];

const Form = ({ courseId, onSubmit }) => {
  const initialValues = {
    name: '',
    birthDate: '2000-01-01',
    email: '',
    phone: '',
    course: courseId || '',
    publicSchool: '',
    madeEnem: '',
    noteEnem: 0,
    hasGraduation: '',
    isPartner: '',
    income: '',
    familyMembers: '',
  };

  const [courses, partners] = useStoreState(({ course, subscription }) => [course.courses, subscription.partners]);

  const listCourses = courses.map(item => ({ label: item?.apelido, value: item?.id }));
  const listPartners = partners.map(item => ({ label: item?.nome, value: item?.id }));

  const [
    registerForDiscount,
    getPartners,
  ] = useStoreActions(({ subscription }) => [
    subscription.registerForDiscount,
    subscription.getPartners,
  ]);

  useEffect(() => {
    getPartners();
  }, [0]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ name, birthDate, email, phone, course, publicSchool, noteEnem, madeEnem, hasGraduation, isPartner, income, familyMembers }, { resetForm }) => {
    if (loading) return;
    setLoading(true);
    
    const date = birthDate.split('-');
    const response = await registerForDiscount({
      nome: name,
      dataNascimento: `${date[2]}/${date[1]}/${date[0]}`,
      telefone: phone?.substring(0,15).replace(/\s|\(|\(|\)|-/gi, ''),
      email,
      idCurso: course,
      diploma: hasGraduation === 'SIM',
      ensinoMedio: publicSchool === 'SIM',
      notaEnem: ((madeEnem && madeEnem == 'SIM') ? noteEnem : 0),
      idEmpresaParceira: isPartner && isPartner !== 'none' ? isPartner : null,
      qtdPessoasFamilia: familyMembers,
      rendaFamiliar: Number(income || 0)
    });

    setLoading(false);
    if (!response) return;
  
    onSubmit();
    resetForm({ values: '' });
  };

  return (
    <Styled.Container container direction="column" alignItems="center">
      <Styled.Title
        bold
        align="center"
      >
        VEJA SE VOCÊ SE ENQUADRA EM ALGUMA BOLSA DE ESTUDO
      </Styled.Title>
      <Text align="center" size={18} style={{ marginTop: 20 }}>
        Atenção: Forneça as informações de forma precisa. Para garantir a sua bolsa, você precisará comprovar com documentos as informações aqui prestadas.
      </Text>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={registerSchema}
      >
        {({ errors, touched, setFieldValue, values }) => (      
          <Styled.FormikForm>
            <Grid direction="column">
              <Grid item>
                <Input
                  name="name"
                  label="Nome"
                  placeholder="Digite seu nome"
                  onChange={(value) => setFieldValue('name', value)}
                />
                {errors.name && touched.name ? <ErrorText text={errors.name} /> : null}
              </Grid>

              <Grid item>
                <Input
                  type="date"
                  name="birthDate"
                  label="Data de Nascimento"
                  defaultValue="2000-01-01"
                  onChange={(value) => setFieldValue('birthDate', value)}
                />
                {errors.birthDate && touched.birthDate ? <ErrorText text={errors.birthDate} /> : null}
              </Grid>

              <Grid item>
                <InputMask
                  mask="(99) 99999-9999"
                  disabled={false}
                  maskChar=" "
                  value={values.phone}
                  onChange={(value) => setFieldValue('phone', value?.target?.value || value)}
                >
                  {(props) => (
                    <Input
                      type="tel"
                      name="phone"
                      label="Telefone"
                      placeholder="Digite seu telefone"
                      variant="filled"
                      id="phone-mask-input"
                      {...props}
                    />
                  )}
                </InputMask>
                {errors.phone && touched.phone ? <ErrorText text={errors.phone} /> : (
                    values.phone && typeof(values.phone) === 'string'
                    && values?.phone.replace(/\s|\(|\(|\)|-/gi, '').length !== 11
                    && !phoneRegExp.test(values.phone) === true
                  ) ? <ErrorText text="Telefone inválido" /> : null
                }
              </Grid>
              
              <Grid item>
                <Input
                  type="email"
                  name="email"
                  label="E-mail"
                  placeholder="Digite seu e-mail"
                  variant="filled"
                  onChange={(value) => setFieldValue('email', value)}
                />
                {errors.email && touched.email ? <ErrorText text={errors.email} /> : null}
              </Grid>

              <Grid item>
                <Select
                  id="selectCourse"
                  name="course"
                  label="Selecione o seu curso"
                  variant="filled"
                  data={listCourses}
                  value={values.course}
                  disabled
                />
                {errors.course && touched.course ? <ErrorText text={errors.course} /> : null}
              </Grid>

              <Grid item>
                <Select
                  name="publicSchool"
                  label="Você concluiu o ensino médio em escola pública?"
                  variant="filled"
                  data={OPTIONS}
                  value={values.publicSchool}
                  onChange={(value) => setFieldValue('publicSchool', value)}
                />
                {errors.publicSchool && touched.publicSchool ? <ErrorText text={errors.publicSchool} /> : null}
              </Grid>

              <Grid item>
                <Select
                  name="madeEnem"
                  label="Você já fez o ENEM?"
                  variant="filled"
                  data={OPTIONS}
                  value={values.madeEnem}
                  onChange={(value) => setFieldValue('madeEnem', value)}
                />
                {errors.madeEnem && touched.madeEnem ? <ErrorText text={errors.madeEnem} /> : null}
              </Grid>

              {values.madeEnem === 'SIM' && (
                <Grid item>
                  <Input
                    type="number"
                    name="noteEnem"
                    label="Qual foi sua nota no ENEM?"
                    placeholder="Qual foi sua nota no ENEM?"
                    onChange={(value) => setFieldValue('noteEnem', value)}
                  />
                  {errors.noteEnem && touched.noteEnem ? <ErrorText text={errors.noteEnem} /> : (
                    values.madeEnem === 'SIM' && !values.noteEnem ? <ErrorText text="Informe sua nota do ENEM" /> : null
                  )}
                </Grid>
              )}

              <Grid item>
                <Select
                  name="hasGraduation"
                  label="Você já possui graduação em nível superior?"
                  variant="filled"
                  data={OPTIONS}
                  value={values.hasGraduation}
                  onChange={(value) => setFieldValue('hasGraduation', value)}
                />
                {errors.hasGraduation && touched.hasGraduation ? <ErrorText text={errors.hasGraduation} /> : null}
              </Grid>

              <Grid item>
                <Select
                  name="isPartner"
                  label="Você trabalha em alguma empresa parceira?"
                  variant="filled"
                  data={[{ label: 'Não trabalho em empresa parceira', value: 'none' }, ...listPartners]}
                  value={values.isPartner}
                  onChange={(value) => setFieldValue('isPartner', value)}
                />
                {errors.isPartner && touched.isPartner ? <ErrorText text={errors.isPartner} /> : null}
              </Grid>

              <Grid item>
                <Input
                  type="number"
                  name="familyMembers"
                  label="Quantas pessoas moram com você?"
                  placeholder="Quantas pessoas moram com você?"
                  onChange={(value) => setFieldValue('familyMembers', value)}
                />
                {errors.familyMembers && touched.familyMembers ? <ErrorText text={errors.familyMembers} /> : null}
              </Grid>

              <Grid item>
                <Input
                  type="number"
                  name="income"
                  label="Qual é a sua renda familiar mensal?"
                  placeholder="Qual é a sua renda familiar mensal?"
                  onChange={(value) => setFieldValue('income', value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                />
                {errors.income && touched.income ? <ErrorText text={errors.income} /> : null}
              </Grid>
              
              <Grid item container justify="center" style={{ padding: 10, marginTop: 40 }}>
                <Button background={Theme.green.secondary} type="submit" border style={{ width: '80%', height: 65 }}>
                  {loading ? (
                    <CircularProgress style={{ color: Theme.white.primary }} size={22} />
                  ) : (
                    <Text color={Theme.white.primary} bold size={16}>
                      AVALIAR MEU PERFIL
                    </Text>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Styled.FormikForm>
        )}
      </Formik>
    </Styled.Container>
  );
};

Form.prototype = {
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
