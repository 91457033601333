import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Btn from 'components/Button';

import WhatsappImage from 'assets/images/whatsappBackground.png';

export const Button = Styled(Btn)`
  height: 42px;
  width: 190px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const Container = Styled(Grid)`
  background-color: ${({ theme }) => theme.green.tertiary};
  border-radius: 8px;
  height: 200px;
  margin-top: 90px;
  padding: 0px;
  width: 800px;

  @media(max-width: 959px) {
    height: auto;
    margin-top: 40px;
    max-width: 400px;
    padding: 20px 0; 
    width: 90%;
  }
`;

export const Image = Styled.img`
  height: 230px;
  position: absolute;
  top: -30px;
`;

export const LeftContainer = Styled(Grid)`
  height: 100%;
  padding-left: 20px;
  width: 100%;

  @media(max-width: 959px) {
    padding: 0 20px;
  }
`;

export const ContainerWithBackground = Styled(Grid)`
  background-image: url(${WhatsappImage});
  background-position: 50px center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 33px;
  padding-top: 15px;
  width: 100%;

  @media(max-width: 959px) {
    background-position: center center;
    padding: 0 20px;
  }
`;

export const RightContainer = Styled(Grid)`
  height: 350px;
  position: relative;
  width: 100%;
`;
