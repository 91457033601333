import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = Styled(Grid)`
  background-color: #191C21;
  flex: 1;
  padding-top: 74px;
  padding-bottom: 43px;

  @media (max-width: 959px) {
    padding-top: 30px;
  }
`;

export const Title = Styled.div`
  color: #FFFFFF;
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: normal;
  max-width: 285px;
  text-align: left;
  text-transform: none;

  @media (max-width: 959px) {
    font-size: 27px;
    text-align: center;
    text-transform: uppercase;
  }
`
