import {
  create
} from 'apisauce';

const API = create({
  baseURL: 'https://www.uniessa.com.br',
  headers: {
    'content-type': 'application/json',
  },
  timeout: 30000,
});

const getPosts = () => API.get('/wp-json/wp/v2/posts');

const getImage = (id) => API.get(`/wp-json/wp/v2/media/${id}`);

export default {
  getPosts,
  getImage,
};