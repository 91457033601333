import React, { useState } from 'react';
import PropsTypes from 'prop-types';
import { Formik  } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';

import { CircularProgress, DialogContentText, Grid } from '@material-ui/core';
import ErrorText from 'components/ErrorText';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Input from 'components/Form/Input';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';

const codeSchema = Yup.object().shape({
  code: Yup
    .string('Informe o seu código')
    .required('Informe o seu código'),
});

const InsertCodeDialog = ({ onClose, open }) => {
  const sendCode = useStoreActions(({ subscription }) => subscription.sendCode);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ code }, { resetForm }) => {
    if (loading) return;
    setLoading(true);

    const response = await sendCode({ codigo: `${code}` });
    
    setLoading(false);
    if (!response) return;
  
    onClose();
    resetForm({ values: '' });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        label="Insira o código Dialog"
        title="Vestibular"
        dark
      >
        <DialogContentText>
          <Styled.Container
            container
            direction="column"
            alignItems="center"
          >
            <Text bold size={18} align="center">
              JÁ TEM UM CÓDIGO?<br/>
              VOCÊ PODE FAZER A SUA PROVA DE VESTIBULAR AGORA MESMO
            </Text>

            <Text align="center" style={{ marginTop: 25 }}>
              O VESTIBULAR DEVERÁ SER REALIZADO DENTRO DAS PRIMEIRAS 48 HORAS APÓS O RECEBIMENTO DO CÓDIGO.
            </Text>

            <Formik
              initialValues={{ code: '' }}
              onSubmit={handleSubmit}
              validationSchema={codeSchema}
            >
              {({ errors, touched, setFieldValue, values }) => (      
                <Styled.FormikForm>
                  <Grid direction="column">
                    <Grid item>
                      <Input
                        type="number"
                        name="code"
                        label="Código"
                        placeholder="Digite o seu código"
                        onChange={(value) => setFieldValue('code', value)}
                        value={values.code}
                      />
                      {errors.code && touched.code ? <ErrorText text={errors.code} /> : null}
                    </Grid>

                    <Grid container justify="center" style={{ marginTop: 25 }}>
                      <Grid item container justify="center" style={{ padding: 10 }}>
                        <Button background={Theme.green.secondary} type="submit" border name="exam_button" style={{ width: '80%', height: 70 }}>
                          {loading ? (
                            <CircularProgress size={22} style={{ color: Theme.black.primary }} />
                          ) : (
                            <Styled.Title color={Theme.black.primary} bold size={16}>
                              QUERO FAZER MEU VESTIBULAR<br/>
                              ONLINE AGORA MESMO
                            </Styled.Title>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Styled.FormikForm>
              )}
            </Formik>

          </Styled.Container>
        </DialogContentText>
      </Dialog>
    </>
  );
};

InsertCodeDialog.defaultProps = {
  open: false,
}

InsertCodeDialog.prototype = {
  onClose: PropsTypes.func.isRequired,
  open: PropsTypes.bool,
}

export default InsertCodeDialog;
