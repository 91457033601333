import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Btn from 'components/Button';

export const Container = Styled(Grid)`
  margin-top: 50px;  
  width: 100%;
`;

export const Button = Styled(Btn)`
  margin-top: 20px;
  padding: 12px 46px;
  border-radius: 4px;
`;

