import { action, thunk } from 'easy-peasy';

import ProfessorAPI from 'api/Professor';

export default {
  loading: false,
  loadingProfessor: false,

  professor: null,
  professors: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  list: thunk(async (actions, course) => {
    actions.setData({ type: 'professors', payload: [] });
    actions.setData({ type: 'loading', payload: true });
  
    const response = await ProfessorAPI.list(course);
    if (response?.ok) {
      actions.setData({ type: 'professors', payload: response.data });
    }

    actions.setData({ type: 'loading', payload: false });
  }),

  get: thunk(async (actions, id) => {
    actions.setData({ type: 'professor', payload: null });
    actions.setData({ type: 'loadingProfessor', payload: true });
  
    const response = await ProfessorAPI.get(id);
    if (response?.ok) {
      actions.setData({ type: 'professor', payload: response.data });
    }

    actions.setData({ type: 'loadingProfessor', payload: false });
  }),
};
