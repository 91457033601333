import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = Styled(Grid)`
  flex: 1;
  margin-top: 60px;
  width: 64%;

  @media (max-width: 959px) {
    width: 100%;
  }
`;

export const VideoContainer = Styled(Grid)`
  flex: 1;
  width: 100%;
`;

export const TextContainer = Styled(Grid)`
  margin-top: 40px;
  padding: 0;
  text-align: justify;
`;
