import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Text from 'components/Text';

import HomeCover from 'assets/images/home.png';

export const Container = Styled(Grid)`
  flex: 1;
  margin-top: -300px;
  padding-left: 8%;
  padding-right: 8%;
  width: 100%;
  font-family: Roboto !important;

  @media(max-width: 959px) {
    margin-top: -150px;
    padding: 0 40px;
  }
`;

export const ContainerProfessors = Styled(Grid)`
  flex: 1;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: Roboto !important;
  background-color: #18191b;
  margin-top: 40px;

  @media (max-width: 959px) {
    background-color: #060606;
    margin-top: 40px;
    padding: 0px 10px;
  }
`;

export const ContainerBottom = Styled(Grid)`
  flex: 1;
  margin-top: 55px;
  padding: 0 8%;
  width: 100%;

  @media (max-width: 959px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

export const ContainerWithBackground = Styled(Grid)`
  background: linear-gradient(to top, rgba(6,6,6,1), rgba(0,0,0,0)), url(${HomeCover});
  backgroud-size: cover;
  margin-top: 47px;
  padding: 0 8%;
  width: 100%;

  @media (max-width: 959px) {
    padding: 0 20px !important;
  }
`;

export const Divider = Styled.div`
  background-color: ${({ theme }) => theme.black.secondary};
  height: 1px;
  margin-top: 65px;
  width: 87.5%;
`;

export const WhatsappContainer = Styled.div`
  margin-top: 40px;

  @media (max-width: 959px) {
    margin-top: 0px;
  }
`;

export const RegisterContainer = Styled.div`
  margin-top: 67px;
  max-width: 1140px;

  @media (max-width: 959px) {
    margin-top: 50px;
  }
`;
 
export const Title = Styled(Text)`
  font-size: 40px;
  width: 100%;

  @media (max-width: 959px) {
    font-size: 22px;
  }
`;
