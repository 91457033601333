
import Styled from 'styled-components';
import { Accordion, Grid } from '@material-ui/core';

export const Container = Styled(Grid)`
  margin-top: 62px;
  padding: 0;
  max-width: 1140px;
`;

export const AccordionContainer = Styled(Accordion)`
  background-color: #191C21 !important;
  border-radius: 5px;
  margin-top: 2px;

  @media (max-width: 959px) {
    margin-top: 4px;
  }
`;
