import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { useStoreActions } from 'easy-peasy';
import InputMask from 'react-input-mask';

import { CircularProgress, Grid } from '@material-ui/core';
import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Text from 'components/Text';
import ThanksDialog from 'components/ThanksDialog';

import Theme from 'style/Theme';
import * as Styled from './styles';

const phoneRegExp = /^\(\d{2}\)?\s*\d{5}\-?\d{4}$/g;

const contactSchema = Yup.object().shape({
  name: Yup
    .string('Informe seu nome')
    .required('Informe seu nome'),
  email: Yup
    .string('Informe seu e-mail')
    .email('Digite um e-mail válido')
    .required('Informe seu e-mail'),
  phone: Yup
    .string('Digite seu celular')
    .test(
      'phoneInvalid',
      'Telefone inválido',
      (value) => {
        return value?.replace(/\s|\(|\(|\)|-/gi, '').length === 11 && value?.replace(/\s/gi, '').length === 14
      }
    )
    .required('Informe seu celular'),
  message: Yup
    .string('Digite uma mensagem')
    .required('Digite uma mensagem'),
});

const initialValues = {
  name: '',
  email: '',
  phone: '',
  message: ''
};

const Form = () => {
  const sendContact = useStoreActions(({ contact }) => contact.send);

  const onSubmit = async ({ name = '', email = '', phone = '', message = '' }, { resetForm }) => {
    if (loading) return;
    setLoading(true);

    const response = await sendContact({
      nome: name,
      telefone: phone?.substring(0,15).replace(/\s|\(|\(|\)|-/gi, ''),
      email,
      msg: message,
    });

    setLoading(false);
  
    if (!response) return;

    handleOpen();
    resetForm({ values: '' });
  };

  const [openThanks, setOpenThanks] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleOpen = () => {
    setOpenThanks(true);
  };

  const handleCloseThanks = () => {
    setOpenThanks(false);
  };

  return (
    <Styled.Container
      item container
      direction="column"
      alignItems="center"
      justify="center"
      id="contato"
    >
      <ThanksDialog
        open={openThanks}
        onClose={handleCloseThanks}
        description="Entraremos em contato em breve."
        dontRefresh
      />
      <Text
        align="center"
        size={32}
        bold
        color={Theme.black.primary}
      >
        Entre em contato
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={contactSchema}
      >
        {({ errors, touched, setFieldValue, values }) => (      
          <FormikForm style={{ width: '100%' }}>
            <Grid
              item container
              direction="column"
              style={{ marginTop: 20 }}
            >
              <Grid item>
                <Styled.Input
                  name="name"
                  label="Nome"
                  placeholder="Digite seu nome"
                />
                {errors.name && touched.name ? <ErrorText text={errors.name} /> : null}
              </Grid>
              
              <Grid item style={{ marginTop: 25 }}>
                <Styled.Input
                  type="email"
                  name="email"
                  label="E-mail"
                  placeholder="Digite seu e-mail"
                  variant="filled"
                />
                {errors.email && touched.email ? <ErrorText text={errors.email} /> : null}
              </Grid>

              <Grid item style={{ marginTop: 25 }}>
                <InputMask
                  mask="(99) 99999-9999"
                  disabled={false}
                  maskChar=" "
                  value={values.phone}
                  onChange={(e) => setFieldValue('phone', e.target.value)}
                >
                  {(props) => (
                    <Styled.Input
                      id="input-contact-phone"
                      type="tel"
                      label="Telefone"
                      placeholder="Digite seu telefone"
                      variant="filled"
                      inputComponent="input"
                      name="phone"
                      {...props}
                    />
                  )}
                </InputMask>
                {errors.phone && touched.phone ? <ErrorText text={errors.phone} /> : (
                    values.phone && typeof(values.phone) === 'string'
                    && values.phone.replace(/\s|\(|\(|\)|-/gi, '').length !== 11
                    && !phoneRegExp.test(values.phone) === true
                  ) ? <ErrorText text="Telefone inválido" /> : null
                }
              </Grid>

              <Grid item style={{ marginTop: 25 }}>
                <Styled.Input
                  name="message"
                  label="Mensagem"
                  placeholder="Digite sua mensagem"
                  style={{ minHeight: 151 }}
                  component="textarea"
                />
                {errors.message && touched.message ? <ErrorText text={errors.message} /> : null}
              </Grid>

              <Grid direction="column" container alignItems="center">
                <Button
                  background={Theme.green.primary}
                  type="submit"
                  style={{ marginTop: 25, minHeight: 44, maxWidth: 287, width: '100%' }}
                >
                  {loading ? (
                    <CircularProgress style={{ color: Theme.white.primary }} size={20} />
                  ) : (
                    <Text size={16} bold>
                      ENVIAR MENSAGEM
                    </Text>
                  )}
                </Button>
                <Text size={14} style={{ marginTop: 18, paddingBottom: 18 }} color={Theme.black.primary}>
                  Em breve entraremos em contato.
                </Text>
              </Grid>

            </Grid>
          </FormikForm>
        )}
      </Formik>
    </Styled.Container>
  );
}

export default Form;
