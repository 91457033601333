import Styled from 'styled-components';
import Btn from 'components/Button';
import Text from 'components/Text';

export const Button = Styled(Btn)`
  height: 70px;
  margin-top: ${({ marginTop }) => marginTop || 40}px;
  padding: 20px 50px;
  max-width: 350px;
  width: 100%;

  @media (max-width: 959px) {
    ${({ sm }) => sm || ''};
  }

  @media (max-width: 700px) {
    max-width: 300px;
  }
`;

export const Title = Styled(Text)`
  color: #fff;
  font-size: 16px;

  @media (max-width: 959px) {
    font-size: 14px;
  }
`;
