import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = Styled(Grid)`
  background: linear-gradient(to top, rgba(6,6,6,1), rgba(0,0,0,0)), url(${({ cover }) => cover});
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  height: 500px;
  width: 100%;

  @media (max-width: 959px) {
    height: 350px;
  }
`;
