import { action, thunk } from 'easy-peasy';

import MainAPI from 'api/Main';

export default {
  loading: false,
  configs: {
    BOTAO_PANDEMIA: false,
    VIDEO_PANDEMIA: null,
    VIDEO_PRINCIPAL: null,
  },

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  setConfig: action((state, { chave, valor }) => {
    state.configs[chave] = valor;
  }),

  getConfig: thunk(async (actions) => {
    actions.setData({ type: 'loading', payload: true });
  
    const response = await MainAPI.getConfig();
    if (response?.ok) {
      for (let i = 0; i < response?.data?.length; i++) {
        actions.setConfig(response?.data[i]);
      }
    }

    actions.setData({ type: 'loading', payload: false });
  }),
};
