import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = Styled(Grid)`
  background-color: #191C21;
  border-radius: 4px;
  margin-bottom: 50px;
  padding: 53px;
  width: 100%;
  max-width: 1140px;

  @media (max-width: 959px) {
    margin-bottom: 0;
    padding: 40px 0;
  }
`;

export const LeftContainer = Styled(Grid)`
  margin-top: 23px;
  padding-left: 40px;

  @media (max-width: 959px) {
    margin-top: 0px;
    padding: 0 20px;
  }
`;

export const RightContainer = Styled(Grid)`
  @media (max-width: 959px) {
    margin-top: 30px;
    width: 100%;
  }
`;

export const Link = Styled.a`
  color: ${({ theme }) => theme.white.primary};
  text-decoration: none;
`;
