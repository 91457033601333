import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = Styled(Grid)`
  flex: 1;
  padding-top: 40px;
`;

export const ListContainer = Styled(Grid)`
  margin-top: 30px;
  max-width: 1140px;
`;
