import React from 'react';
import ElasticCarousel from 'react-elastic-carousel';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Grid, Hidden } from '@material-ui/core';
import Text from 'components/Text';

import * as Styled from './styles';
import Theme from 'style/Theme';

const Carousel = ({ loading, phrases }) => {

  const renderItem = (item) => (
    <Grid
      item
      container
      direction="row"
      style={{ margin: 15, borderRadius: 4, backgroundColor: '#fff', padding: '16px 30px', width: '100%', minHeight: 121 }}
      key={`${item?.id}`}
    >
      <Grid item container direction="column" justify="space-between" xs={12} sm={12} md={9}>
        <Text color={Theme.black.primary} size={14} italic>
          {item?.texto}
        </Text>
        <Text size={18} weight={600} color={Theme.green.primary} style={{ marginTop: 5 }}>
          {item?.nome}
        </Text>
      </Grid>
      <Grid
        item container
        md={3}
        alignItems="center"
        justify="flex-end"
      >
        <Styled.Image src={item?.foto} alt={item?.nome} name={item?.nome} />
      </Grid>
    </Grid>
  );

  const renderPagination = ({ pages, activePage, onClick }) => (
    <Styled.PaginationContainer
      container
      justify="center"
      alignItems="center"
    >
      {pages.map((page, index) => (
        <Grid
          item
          onClick={() => onClick(page)}
          key={`${index}`}
        >
          {page === activePage ? <Styled.CheckedIcon/> : <Styled.UncheckedIcon/>}
        </Grid>
      ))}
    </Styled.PaginationContainer>
  )

  const renderCarousel = () => {
    if (loading) {
      return (
        <Grid
          container
          justify="space-between"
          style={{ width: '100%' }}
        >
          <Grid item xs={12} sm={12} md={5} >
            <SkeletonTheme color="#202020" highlightColor="#444" width="100%">
              <Styled.SkeletonContent width="100%" />
            </SkeletonTheme>
          </Grid>
          <Grid item xs={0} sm={0} md={5}>
            <SkeletonTheme color="#202020" highlightColor="#444" width="100%">
              <Skeleton height={150} width="100%" />
            </SkeletonTheme>
          </Grid>
        </Grid>
      )
    }

    if (!phrases || !phrases.length) {
      return null
    }

    return (
      <>
        <Hidden smDown>
          <ElasticCarousel
            itemsToShow={2}
            itemsToScroll={2}
            showArrows={false}
            enableSwipe
            enableMouseSwipe
            enableAutoPlay
            autoPlaySpeed={7000}
            renderPagination={renderPagination}
          >
            {phrases.map((item, index) => renderItem(item))}
          </ElasticCarousel>
        </Hidden>
        <Hidden mdUp>
          <ElasticCarousel
            itemsToShow={1}
            itemsToScroll={1}
            showArrows={false}
            enableSwipe
            enableMouseSwipe
            enableAutoPlay
            autoPlaySpeed={7000}
            renderPagination={renderPagination}
          >
            {phrases.map((item, index) => renderItem(item))}
          </ElasticCarousel>
        </Hidden>
      </>
    )
  };

  return (
    <Styled.Container>
      {renderCarousel()}
    </Styled.Container>
  );
}

Carousel.defaultProps = {
  loading: false,
  phrases: [],
};

Carousel.prototype = {
  loading: PropTypes.bool,
  phrases: PropTypes.array,
};

export default Carousel;
