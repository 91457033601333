import API from 'api';

const get = (id) => API.get(`/curso/${id}`);

const list = () => API.get('/curso');

export default {
  get,
  list,
};
