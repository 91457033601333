import Styled from 'styled-components';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Skeleton from 'react-loading-skeleton';
import { Grid } from '@material-ui/core';

export const Pagination = Styled(Grid)`
  margin-top: 10px;
  width: 100%;

  @media (max-width: 959px) {
    margin-top: 0px;
  }
`;

export const Image = Styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  backgorund-repeat: no-repeat;
  backgroound-position: center center;
  border-radius: 4px;
  height: 350px;
  margin-bottom: 5px;
  width: 100%;
`;

export const ItemContainer = Styled(Grid)`
  cursor: pointer;
  margin-top: 15px;
  padding: 0 15px;
  width: 100%;
`

export const UncheckedIcon = Styled(RadioButtonUncheckedIcon)`
  color: ${({ theme }) => theme.white.primary};
  cursor: pointer;
  font-size: 12px;
  margin-right: 15px;
`;

export const CheckedIcon = Styled(RadioButtonCheckedIcon)`
  color: ${({ theme }) => theme.green.primary};
  cursor: pointer;
  font-size: 12px;
  margin-right: 15px;
`;

export const Text = Styled.p`
  color: white;
  display: -webkit-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  line-height: 18px;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-weight: 500;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SkeletonContainer = Styled.div`
  border-radius: 2px;
  height: 350px;
  margin-top: 15px;
  padding: 0 15px;
  width: 100%;

  @media (max-width: 959px) {
    align-items: center;
    justify-content: center;
    margin-top: 22px;
    padding: 0;
    width: 80%;
  }
`;

export const SkeletonContent = Styled(Skeleton)
`
  border-radius: 4px;
  height: 350;
  margin-bottom: 5px;
  width: 100%;
`;
