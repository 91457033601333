import React from 'react';
import { useStoreState } from 'easy-peasy';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Grid } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const FAQ = () => {
  const [course, faqs, loading] = useStoreState(({ course, faq }) => [course.course, faq.faqs, faq.loading]);

  const renderFaqs = () => {
    if (loading) {
      return (
        <Grid container direction="column" alignItems="center">
          <Grid item style={{ width: '100%' }}>
            <SkeletonTheme item color="#202020" highlightColor="#444">
              <Skeleton height={70} width="100%" />
              <Skeleton height={70} width="100%" style={{ marginTop: 15 }} />
              <Skeleton height={70} width="100%" style={{ marginTop: 15 }} />
            </SkeletonTheme>
          </Grid>
        </Grid>
      );
    }

    if (!faqs.length) {
      return (
        <Grid container justify="center">
          <Text size={18}>Tem alguma dúvida sobre o curso? Entre em contato com a gente.</Text>
        </Grid>
      )
    }

    return (
      <>
        <Styled.AccordionContainer style={{ width: '100%' }}>
          <AccordionSummary
            style={{ height: 70 }}
            expandIcon={<ExpandMoreIcon style={{ color: Theme.white.primary }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>Qual é a grade horária deste curso?</span>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ padding: 10, paddingTop: 0, width: '100%' }}>
              <TableContainer>
                <Table aria-label="simple table" component={Paper}>
                  <TableHead style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                    <TableRow>
                      <TableCell>Disciplina</TableCell>
                      <TableCell align="center">Período</TableCell>
                      <TableCell align="center">Carga Horária</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {course?.grade?.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.disciplina}
                        </TableCell>
                        <TableCell align="center">{row.periodo}</TableCell>
                        <TableCell align="center">{row.cargaHoraria}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AccordionDetails>
        </Styled.AccordionContainer>  
  
        {faqs.map((item, index) => (
          <Styled.AccordionContainer key={`${index}_${item?.id}`}>
            <AccordionSummary
              style={{ height: 70 }}
              expandIcon={<ExpandMoreIcon style={{ color: Theme.white.primary }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span>{item?.pergunta}</span>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <Text size={13} style={{ marginTop: 15 }}>
                  {item?.resposta}
                </Text>
              </div>
            </AccordionDetails>
          </Styled.AccordionContainer>    
        ))}
      </>
    );
  };

  return (
    <Styled.Container
      container
      direction="column"
      alignItems="center"
    >
      <Text bold size={28} style={{ marginBottom: 57 }}>PERGUNTAS FREQUENTES</Text>
      {renderFaqs()}
    </Styled.Container>
  );
};

export default FAQ;
