import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Text from 'components/Text';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Theme from 'style/Theme';

const BackButton = ({ course }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/curso/${course}`);
    window.scrollTo(0, 0);
  }

  return (
    <Grid container justify="center" style={{ marginTop: 35 }}>
      <KeyboardBackspaceIcon
        style={{ color: Theme.green.primary, cursor: 'pointer' }}
        onClick={handleBack}
      />
      <div onClick={handleBack} style={{ marginLeft: 10, cursor: 'pointer' }}>
        <Text size={16}>
          Voltar para página do curso
        </Text>
      </div>
    </Grid>
  )
};

BackButton.defaultProps = {
  professor: null,
};

BackButton.prototype = {
  course: PropTypes.any.isRequired,
  professor: PropTypes.object,
};

export default BackButton;
