import React from 'react';

import Text from 'components/Text';
import Form from './components/Form';

import Theme from 'style/Theme';
import * as Styled from './styles';

const ContactForm = () => (
  <Styled.Container container>
    <Styled.LeftContainer sm={12} md={7} item container direction="column">
      <Text size={32} bold>CONTATO</Text>

      <Text size={20} color={Theme.green.secondary} style={{ marginTop: 15, marginBottom: 5, fontWeight: 'bold' }}>
        Endereço
      </Text>
      <Text size={14} weight={400} height={20}>
        Av. das Américas, 505 - Morada da Colina, <br />
        Uberlândia - Minas Gerais
      </Text>

      <Text size={20} color={Theme.green.secondary} style={{ marginTop: 15, marginBottom: 5, fontWeight: 'bold' }}>
        Funcionamento
      </Text>
      <Text size={14} weight={400} height={20}>
        Geral: Segunda à Sexta - 13h às 22h<br />
        Setor de Matrículas: Segunda à Sexta - 9h às 21h
      </Text>

      <Text size={20} color={Theme.green.secondary} style={{ marginTop: 15, marginBottom: 5, fontWeight: 'bold' }}>
        WhatsApp
      </Text>
      <Text size={14} weight={400} height={20}>
        Setor de Matrículas: <Styled.Link href="tel:34991710334">(34) 99171 - 0334</Styled.Link><br />
        Secretaria Acadêmica: <Styled.Link href="tel:34999205000">(34) 99920 - 5000</Styled.Link><br />
        Secretaria Financeira: <Styled.Link href="tel:34999445800">(34) 99944 - 5800</Styled.Link>
      </Text>

      <Text size={20} color={Theme.green.secondary} style={{ marginTop: 15, marginBottom: 5, fontWeight: 'bold' }}>
        E-mail
      </Text>
      <Text size={14} weight={400} height={20}>
        Biblioteca: <Styled.Link href="mailto:biblioteca@uniessa.com.br">biblioteca@uniessa.com.br</Styled.Link><br />
        CPA: <Styled.Link href="mailto:cpa@uniessa.com.br">cpa@uniessa.com.br</Styled.Link><br />
        Diretor Geral: <Styled.Link href="mailto:andersonthomas@uniessa.com.br">andersonthomas@uniessa.com.br</Styled.Link><br />
        Diretoria: <Styled.Link href="mailto:diretor@uniessa.com.br">diretor@uniessa.com.br</Styled.Link><br />
        Secretaria Acadêmica: <Styled.Link href="mailto:secretaria@uniessa.com.br">secretaria@uniessa.com.br</Styled.Link><br />
        Secretaria Financeira: <Styled.Link href="mailto:secretariafinanceira@uniessa.com.br">secretariafinanceira@uniessa.com.br</Styled.Link><br />
        Setor de Matrículas: <Styled.Link href="mailto:matricula@uniessa.com.br">matricula@uniessa.com.br</Styled.Link><br />
        Suporte Técnico Plataforma: <Styled.Link href="mailto:suporte@uniessa.com.br">suporte@uniessa.com.br</Styled.Link><br />
        Ouvidoria: <Styled.Link href="mailto:ouvidoria@uniessa.com.br">ouvidoria@uniessa.com.br</Styled.Link>
      </Text>
    </Styled.LeftContainer>

    <Styled.RightContainer
      sm={12} md={5}
      item container
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Form />
    </Styled.RightContainer>
  </Styled.Container>
);

export default ContactForm;
