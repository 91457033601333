import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Text from 'components/Text';

export const Container = Styled(Grid)`
  flex: 1;
  width: 100%;
`;

export const ListContainer = Styled(Grid)`
  margin-top: 44px;
  max-width: 1140px;
  width: 100%;

  @media (max-width: 959px) {
    margin-top: 20px;
  }
`;

export const Title = Styled(Text)`
  font-size: 26px;

  @media (max-width: 959px) {
    font-size: 18px;
  }
`;
