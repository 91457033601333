import Styled from 'styled-components';
import {
  Grid
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import Text from 'components/Text';

import FeaturedIcon from 'assets/images/featured.png';

export const Container = Styled(Grid)
`
  margin-top: 0px;
  padding: 20px;
  width: 100%;

  @media (max-width: 700px) {
    padding: 6px;
  }
`;

export const Content = Styled(Grid)
`
  cursor: pointer;
  max-width: 285px;
`;

export const Image = Styled(Grid)
`
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url(${({ icon, image }) => icon ? icon : image});
  background-size: ${({ image }) => image ? 'cover' : '100px 100px'};
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
  height: 200px;
  width: 100%;
`;

export const SkeletonContainer = Styled.div`
  border-radius: 2px;
  height: 200px;
  width: 100%;
`;

export const SkeletonContent = Styled(Skeleton)
`
  border-radius: 2px;
  height: 350px;
  width: 255px;
`;

export const Featured = Styled(Grid)
`
  background-image: url(${FeaturedIcon});
  background-size: contain;
  height: 39px;
  margin-right: 11px;
  width: 39px;
`;

export const Title = Styled(Text)`
  margin-bottom: 30px;
  padding: 0 15px;
  // white-space: nowrap;
  width: 100%;
`;