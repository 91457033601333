import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik  } from 'formik';
import * as Yup from 'yup';
import { useStoreActions, useStoreState } from 'easy-peasy';
import InputMask from 'react-input-mask';

import { CircularProgress, Grid } from '@material-ui/core';
import Text from 'components/Text';
import ErrorText from 'components/ErrorText';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

import CodeDialog from 'components/CodeDialog';
import InsertCodeDialog from 'components/InsertCodeDialog';
import ThanksDialog from 'components/ThanksDialog';

import Theme from 'style/Theme';
import * as Styled from './styles';

const phoneRegExp = /^\(\d{2}\)?\s*\d{5}\-?\d{4}$/g;

const registerSchema = Yup.object().shape({
  name: Yup
    .string('Informe seu nome')
    .required('Informe seu nome'),
  birthDate: Yup
    .string('Informe sua data de nascimento')
    .required('Informe sua data de nascimento'),
  email: Yup
    .string('Informe seu e-mail')
    .email('Digite um e-mail válido')
    .required('Informe seu e-mail'),
  phone: Yup
    .string('Digite seu celular')
    .test(
      'phoneInvalid',
      'Telefone inválido',
      (value) => {
        return value?.replace(/\s|\(|\(|\)|-/gi, '').length === 11 && value?.replace(/\s/gi, '').length === 14
      }
    )
    .required('Informe seu celular'),
  course: Yup
  .string('Selecione o seu curso')
  .required('Selecione o seu curso'),
});

const RegisterForm = ({ courseId, onSubmit }) => {
  const initialValues = {
    name: '',
    birthDate: '2000-01-01',
    email: '',
    phone: '',
    course: courseId || ''
  };

  const [
    courses,
    loadingForContact,
    loadingForRegister,
  ] = useStoreState(({ course, subscription }) => [
    course.courses,
    subscription.loadingForContact,
    subscription.loadingForRegister,
  ]);

  const [
    register,
    registerForContact,
  ] = useStoreActions(({ subscription }) => [
    subscription.register,
    subscription.registerForContact,
  ]);

  const list = courses.map(item => ({ label: item?.apelido, value: item?.id }));

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [openThanks, setOpenThanks] = useState(false);
  const [openCode, setOpenCode] = useState(false);
  const [openInsertCode, setOpenInsertCode] = useState(false);

  const handleClose = () => {
    setOpenThanks(false);
  };

  const handleCloseCode = () => {
    setOpenCode(false);
  };

  const handleOpenInsertCode = () => {
    setOpenInsertCode(true);
  };

  const handleCloseInsertCode = () => {
    setOpenInsertCode(false);
  };

  const handleOnSubmit = async (type, values, resetForm) => {
    const { name, birthDate, email, phone, course } = values;
    if (isSubmiting || !name || !birthDate || !email || !phone || !course) return;
  
    if (type === 0) return handleSubmitContact(values, resetForm);
    return handleSubmitRegister(values, resetForm);
  };

  const handleSubmitRegister = async ({ name, birthDate, email, phone, course }, resetForm) => {

    if(!phone.substring) return;

    if (isSubmiting) return;
    setIsSubmiting(true);

    const date = birthDate.split('-');
    const response = await register({
      nome: name,
      dataNasc: `${date[2]}/${date[1]}/${date[0]}`,
      email,
      celular: phone?.substring(0,15).replace(/\s|\(|\(|\)|-/gi, ''),
      idCurso: course,
    });

    if (!response) {
      return setIsSubmiting(false);
    }

    onSubmit();
    setIsSubmiting(false);
    setOpenCode(true);
    resetForm({ values: '' });
  }

  const handleSubmitContact = async ({ name, birthDate, email, phone, course }, resetForm) => {

    if(!phone.substring) return;

    if (isSubmiting) return;
    setIsSubmiting(true);

    const date = birthDate.split('-');
    const response = await registerForContact({
      nome: name,
      dataNasc: `${date[2]}/${date[1]}/${date[0]}`,
      email,
      celular: phone?.substring(0,15).replace(/\s|\(|\(|\)|-/gi, ''),
      idCurso: course,
    });

    if (!response) {
      return setIsSubmiting(false);
    }

    setOpenThanks(true);
    setIsSubmiting(false);

    resetForm({ values: '' });
  }

  return (
    <Styled.Container container direction="column" alignItems="center">
      <ThanksDialog
        open={openThanks}
        onClose={handleClose}
        description="Entraremos em contato em breve."
      />
      <CodeDialog
        open={openCode}
        onClose={handleCloseCode}
      />
      <InsertCodeDialog
        open={openInsertCode}
        onClose={handleCloseInsertCode}
      />
      <Text
        size={32}
        bold
        align="center"
      >
        INSCREVA-SE AGORA
      </Text>
      <Text align="center" size={18} style={{ marginTop: 20 }}>
        Garanta já sua vaga na melhor faculdade particular de Uberlândia segundo o MEC.
      </Text>

      <Formik
        initialValues={initialValues}
        onSubmit={() => null}
        validationSchema={registerSchema}
      >
        {({ errors, handleSubmit, resetForm, setFieldValue,  touched, values }) => (      
          <Styled.FormikForm>
            <Grid direction="column">
              <Grid item>
                <Input
                  id="inputName"
                  name="name"
                  label="Nome"
                  placeholder="Digite seu nome"
                  onChange={(value) => setFieldValue('name', value)}
                  value={values.name}
                />
                {errors.name && touched.name ? <ErrorText text={errors.name} /> : null}
              </Grid>

              <Grid item>
                <Input
                  id="inputBirthDate"
                  type="date"
                  name="birthDate"
                  label="Data de Nascimento"
                  defaultValue="2000-01-01"
                  onChange={(value) => setFieldValue('birthDate', value)}
                  value={values.birthDate}
                />
                {errors.birthDate && touched.birthDate ? <ErrorText text={errors.birthDate} /> : null}
              </Grid>

              <Grid item>
                <InputMask
                  id="inputPhone"
                  mask="(99) 99999-9999"
                  disabled={false}
                  maskChar=" "
                  value={values.phone}
                  onChange={(value) => setFieldValue('phone', value?.target?.value || value)}
                >
                  {(props) => (
                    <Input
                      type="tel"
                      name="phone"
                      label="Celular"
                      placeholder="Digite seu celular"
                      variant="filled"
                      id="phone-mask-input"
                      {...props}
                    />
                  )}
                </InputMask>
                {errors.phone && touched.phone ? <ErrorText text={errors.phone} /> : (
                    values.phone && typeof(values.phone) === 'string'
                    && values?.phone?.replace(/\s|\(|\(|\)|-/gi, '').length !== 11
                    && !phoneRegExp.test(values.phone) === true
                  ) ? <ErrorText text="Telefone inválido" /> : null
                }
              </Grid>

              <Grid item>
                <Input
                  id="inputEmail"
                  type="email"
                  name="email"
                  label="E-mail"
                  placeholder="Digite seu e-mail"
                  variant="filled"
                  onChange={(value) => setFieldValue('email', value)}
                  value={values.email}
                />
                {errors.email && touched.email ? <ErrorText text={errors.email} /> : null}
              </Grid>

              <Grid item>
                <Select
                  id="selectCourse"
                  name="course"
                  label="Selecione o seu curso"
                  variant="filled"
                  data={list}
                  value={values.course}
                  onChange={(value) => setFieldValue('course', value)}
                  disabled
                />
                {errors.course && touched.course ? <ErrorText text={errors.course} /> : null}
              </Grid>

              <Grid container justify="space-between" style={{ marginTop: 30 }}>
                <Grid item container justify="center" sm={12} md={6}>
                  <Styled.BtnBorder
                    background="transparent"
                    name="contact_button"
                    disabled={isSubmiting || loadingForContact}
                    onClick={() => {
                      handleSubmit();
                      handleOnSubmit(0, values, resetForm);
                    }}
                  >
                    {loadingForContact ? (
                      <CircularProgress style={{ color: Theme.green.primary }} size={22} />
                    ) : (
                      <Text weight={700} size={16}>
                        QUERO QUE A UNIESSA ENTRE<br/>
                        EM CONTATO COMIGO
                      </Text>
                    )}
                    
                  </Styled.BtnBorder>
                </Grid>
                
                <Grid item container justify="center" sm={12} md={6}>
                  <Styled.ButtonSubmitContainer
                    background={Theme.green.secondary}
                    border
                    submit
                    name="exam_button"
                    disabled={isSubmiting || loadingForRegister}
                    onClick={() => {
                      handleSubmit();
                      handleOnSubmit(1, values, resetForm);
                    }}
                  >
                    {loadingForRegister ? (
                      <CircularProgress style={{ color: Theme.black.primary }} size={22} />
                    ) : (
                      <Text color={Theme.black.primary} weight={700} size={16}>
                        QUERO FAZER MEU VESTIBULAR<br/>
                        ONLINE AGORA MESMO
                      </Text>
                    )}
                  </Styled.ButtonSubmitContainer>
                </Grid>
              </Grid>
            
              <div onClick={handleOpenInsertCode}>
                <Styled.AlderadyRegistered align="center" size={16}>
                  Já fez a sua inscrição e tem um código? Clique aqui
                </Styled.AlderadyRegistered>
              </div>
              
            </Grid>
          </Styled.FormikForm>
        )}
      </Formik>
    </Styled.Container>
  );
};

RegisterForm.defaultProps = {
  courseId: '',
  onSubmit: () => null,
};

RegisterForm.prototype = {
  courseId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default RegisterForm;
