import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = Styled(Grid)`
  flex: 1;
  margin-top: -300px;
  padding-left: 8%;
  padding-right: 8%;
  width: 100%;
  font-family: Roboto !important;

  @media(max-width: 959px) {
    margin-top: -120px;
    padding: 0 20px;
  }
`;

export const ContactContainer = Styled(Grid)`
  margin-top: 86px;
  width: 100%;

  @media(max-width: 959px) {
    margin-top: 50px;
  }
`;

export const ProfessorContainer = Styled.div`
  margin-top: 48px;
  width: 100%;

  @media (max-width: 959px) {
    margin-top: 35px;
    padding: 0px;
  }
`;
