import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import Card from 'components/Card';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';

const Courses = ({ list, loading, subtitle, title }) => {
  const navigate = useNavigate();

  const handleClick = (course) => {
    navigate(`/curso/${course?.id}`);
    window.scrollTo(0, 0);
  };

  const renderList = () => {
    if (loading) {
      return (
        <Grid container alignItems="center" justify="center">
          <Styled.ListContainer container justify="flex-start" wrap="wrap">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
              <Card
                key={`${index}`}
                isLoading
                onClick={() => null}
                twoPerLine
              />
            ))}
          </Styled.ListContainer>
        </Grid>
      );
    }

    if (!list && !list.length) {
      return (
        <Styled.ListContainer container justify="center">
          <Text>Falha no carregamento dos cursos, atualize a página e tente novamente.</Text>
        </Styled.ListContainer>
      )
    }

    return (
      <Grid container alignItems="center" justify="center" style={{ width: '100%' }}>
        <Styled.ListContainer container justify="flex-start" wrap="wrap">
          {list.map((item, index) => (
            <Card
              key={`${index}_${item?.id}`}
              icon={item?.iconeCurso}
              onClick={() => handleClick(item)}
              titleInside={item?.apelido}
              twoPerLine
            />
          ))}
        </Styled.ListContainer>
      </Grid>
    );
  };

  return (
    <Styled.Container container direction="column" alignItems="center" justify="center">
      <Text size={32} align="center" weight={700} bold>
        {title}
        {subtitle && (
          <span style={{ color: Theme.green.secondary }}>
            {` ${subtitle}`}
          </span>
        )}
      </Text>

      {renderList()}
    </Styled.Container>
  );
};

Courses.defaultProps = {
  list: [],
  loading: true,
  subtitle: '',
  title: '',
}

Courses.prototype = {
  list: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default Courses;
