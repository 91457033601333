import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import PriceCard from 'components/PriceCard';
import Text from 'components/Text';
import ThanksDialog from 'components/ThanksDialog';
import CodeDialog from './components/CodeDialog';
import ResultDialog from './components/ResultDialog';
import ScholarshipDialog from './components/ScholarshipDialog';

import Theme from 'style/Theme';
import * as Styled from './styles';

const Price = () => {
  const [open, setOpen] = React.useState(false);
  const [openResult, setOpenResult] = React.useState(false);
  const [openThanks, setOpenThanks] = React.useState(false);
  const [openCode, setOpenCode] = React.useState(false);

  const [
    course,
    loading,
    student,
  ] = useStoreState(({ course, subscription }) => [
    course.course,
    course.loading,
    subscription.student,
  ]);
  const [
    register,
    registerForPresential,
  ] = useStoreActions(({ subscription }) => [
    subscription.register,
    subscription.registerForPresential,
  ])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (result) => {
    if (result) {
      setOpenResult(true);
    }
    setOpen(false);
  };

  const handleCloseResult = async (response) => {
    if (response === 'THANKS') {
      let response = await registerForPresential({
        nome: student?.nome,
        celular: student?.telefone,
        email: student?.email,
        idCurso: student?.idCurso,
        dataNasc: student?.dataNascimento,
      });

      if (!response) return;

      setOpenThanks(true);
    } else if (response === 'SUBSCRIBE') {
      let response = await register({
        nome: student?.nome,
        celular: student?.telefone,
        email: student?.email,
        idCurso: student?.idCurso,
        dataNasc: student?.dataNascimento,
      });

      if (!response) return;

      setOpenCode(true);
    }
  
    setOpenResult(false);
  };

  const handleCloseThanks = () => {
    setOpenThanks(false);
  };

  const handleCloseCode = () => {
    setOpenCode(false);
  };

  return (
    <Styled.Container
      container
      direction="column"
      alignItems="center"
    >
      <ResultDialog onClose={handleCloseResult} open={openResult} course={course} />
      <ScholarshipDialog onClose={handleClose} open={open} courseId={course?.id} />
      <ThanksDialog
        open={openThanks}
        onClose={handleCloseThanks}
        description="Entraremos em contato em breve para agendar sua avaliação presencial."
      />
      <CodeDialog onClose={handleCloseCode} open={openCode} />

      <Text bold size={28}>MENSALIDADE</Text>
      <Styled.Content
        item container
        justify="center"
      >
        <PriceCard
          background={Theme.black.primary}
          description={`R$ ${course?.valorMesalidade}`}
          descriptionColor={Theme.white.primary}
          showIcon
          title="Valor da mensalidade"
          titleColor={Theme.white.primary}
          loading={loading}
        />
        <PriceCard
          background={Theme.white.primary}
          description={`R$ ${course?.valorLiquido}`}
          descriptionColor={Theme.green.primary}
          title="Valor da mensalidade com desconto"
          titleColor={Theme.black.primary}
          loading={loading}
        />
        <PriceCard
          background="transparent"
          border
          cursor
          onClick={handleOpen}
          title="Clique aqui e veja se você se enquadra nas nossas bolsas de estudo"
          titleColor={Theme.white.primary}
          loading={loading}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default Price;
