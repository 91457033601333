import React from 'react';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Text from 'components/Text';

import * as Styled from './styles';

const OverView = ({ professor }) => (
  <Styled.Container
    container
    direction="column"
    alignItems="center"
  >
    <Text bold size={32} transform="uppercase" align="center" style={{ width: '100%' }}>
      {professor?.nome || (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton height={50} style={{ width: '100%', minWidth: 250, maxWidth: 320 }} />
        </SkeletonTheme>
      )}
    </Text>
    
    <Text size={22} align="center" style={{ width: '100%' }}>
      {professor?.titulacao || (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton height={25} width={150} />
        </SkeletonTheme>
      )}
    </Text>

    {professor?.video && (
      <Styled.VideoContainer item>
        <video
          class="video-js"
          controls
          preload="auto"
          title="Uniessa Institucional - Professor"
          data-setup="{}"
          style={{ width: '100%', height: '100%' }}
        >
          <source src={professor?.video} type="video/mp4" />
          <p class="vjs-no-js">
            Para visualizar esse video, você precisa habilitar o JavaScript e/ou atualizar o seu navegador.
          </p>
        </video>
      </Styled.VideoContainer>
    )}
  
    <Styled.TextContainer item>
      <Text size={16} style={{ width: '100%' }}>
        {professor?.curriculo}
      </Text>
    </Styled.TextContainer>
  </Styled.Container>
);

OverView.defaultProps = {
  professor: null,
};

OverView.prototype = {
  professor: PropTypes.object,
};

export default OverView;
