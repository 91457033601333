import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';

import Theme from 'style/Theme';

const ErrorText = ({ text }) => (
  <Text
    color={Theme.red.primary}
    style={{ marginTop: 10 }}
  >
    {text}
  </Text>
);

ErrorText.defaultProps = {
  text: '',
};

ErrorText.prototype = {
  text: PropTypes.string,
};

export default ErrorText;
