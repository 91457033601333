import Styled from 'styled-components';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Grid } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

export const Container = Styled(Grid)`
  margin-top: 45px;
  width: 80%;

  @media(max-width: 959px) {
    width: 100%;
  }
`;

export const Image = Styled.img`
  border-radius: 35px;
  height: 70px;
  width: 70px;

  @media(max-width: 959px) {
    display: none;
  }
`;

export const UncheckedIcon = Styled(RadioButtonUncheckedIcon)`
  color: ${({ theme }) => theme.white.primary};
  cursor: pointer;
  font-size: 10px;
  margin-right: 15px;
`;

export const CheckedIcon = Styled(RadioButtonCheckedIcon)`
  color: ${({ theme }) => theme.green.primary};
  cursor: pointer;
  font-size: 11px;
  margin-right: 15px;
`;

export const PaginationContainer = Styled(Grid)`
  margin-top: 20px;

  @media(max-width: 959px) {
    margin-top: 0px;
  }
`;

export const SkeletonContent = Styled(Skeleton)`
  height: 150px;
  
  @media (max-width: 959px) {
    height: 100px;
  }
`;
