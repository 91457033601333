import API from 'api';

const getPartners = () => API.get(`/bolsa/empresa`);

const register = (body) => API.post(`/vestibular/inscricao`, body);

const registerForContact = (body) => API.post(`/vestibular/contato`, body);

const registerForDiscount = (body) => API.post(`/bolsa`, body);

const registerForPresential = (body) => API.post(`/vestibular/presencial`, body);

const sendCode = (body) => API.post(`/vestibular/codigo`, body);

export default {
  getPartners,
  register,
  registerForContact,
  registerForDiscount,
  registerForPresential,
  sendCode,
};
