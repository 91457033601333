import Styled from 'styled-components';

export const Container = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  margin-bottom: 0px;
  width: 100%;

  @media (max-width: 959px) {
    height: auto;
  }
`;

export const Content = Styled.div`
  position: absolute;
  top: 0;
  color:#f1f1f1;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  -moz-box-shadow: inset 0 -128px 66px -28px rgb(21 24 28);
  -webkit-box-shadow: inset 0 -128px 66px -28px rgb(21 24 28);
  box-shadow: inset 0 -128px 66px -28px rgb(21 24 28);
`;

export const SubContent = Styled.div`
  text-align: center;
  padding: 10px;
`;

export const Video = Styled.video`
  height: 100vh;
  width: 100%;
  object-fit: cover;
`;
