import { Grid } from '@material-ui/core';
import Styled from 'styled-components';

export const Container = Styled(Grid)`
  background-color: ${({ theme }) => theme.background};
  margin-top: 78px;
  padding: 0 8%;

  @media (max-width: 959px) {
    margin-top: 0;
    padding-top: 25px;
    padding-bottom: 35px;
  }
`;

export const Divider = Styled.div`
  background-color: ${({ theme }) => theme.black.secondary};
  height: 1px;
  margin-top: 86px;
  width: 100%;

  @media (max-width: 959px) {
    margin-top: 40px;
  }
`;

export const Link = Styled.a`
  text-decoration: none;
`;
