import React from 'react';
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import { SkeletonTheme } from 'react-loading-skeleton';

import Text from 'components/Text';
import Carousel from './components/Carousel';

import Theme from 'style/Theme';
import * as Styled from './styles';

const Review = ({ video }) => {
  const [phrases, loading] = useStoreState(({ phrases }) => [phrases.phrases, phrases.loading]);

  return (
    <Styled.Container
      container
      alignItems="center"
      direction="column"
    >
      <Text
        size={22}
        transform="none"
        style={{ width: '100%' }}
        bold
        align="center"
      >
        Veja o que 
        <span style={{ color: Theme.green.primary }}>
        {` nossos alunos `}
      </span>
        estão falando sobre este curso
      </Text>

      <Styled.VideoContainer item container justify="center">
        {loading ? (
            <SkeletonTheme color="#202020" highlightColor="#444">
              <Styled.SkeletonContent />
            </SkeletonTheme>
          ) : (
            <video
              class="video-js"
              controls
              preload="auto"
              title="Uniessa Institucional - Depoimentos"
              data-setup="{}"
              style={{ width: '100%', height: '100%' }}
            >
              <source src={video} type="video/mp4" />
              <p class="vjs-no-js">
                Para visualizar esse video, você precisa habilitar o JavaScript e/ou atualizar o seu navegador.
              </p>
            </video>
        )}
      </Styled.VideoContainer>

      <Carousel phrases={phrases} loading={loading} />
      
    </Styled.Container>
  );
};

Review.defaultProps = {
  video: null,
};

Review.prototype = {
  video: PropTypes.string,
};

export default Review;
