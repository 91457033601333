import React from 'react';
import PropsTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';
import Dialog from 'components/Dialog';
import Text from 'components/Text';

const PandemiaDialog = ({ configs, onClose, open }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      label="Pandemia Dialog"
      title="Como estamos funcionando durante a pandemia"
    >
      <DialogContentText>
        <video
          class="video-js"
          controls
          preload="auto"
          title="Como estamos funcionando durante a pandemia"
          data-setup="{}"
          style={{ width: '100%', height: '100%', minHeight: 500 }}
        >
          <source src={configs?.VIDEO_PANDEMIA} type="video/mp4" />
          <p class="vjs-no-js">
            Para visualizar esse video, você precisa habilitar o JavaScript e/ou atualizar o seu navegador.
          </p>
        </video>

        <Text color="#222" style={{ marginTop: 10 }}>
          {configs?.TEXTO_PANDEMIA}
        </Text>
      </DialogContentText>
    </Dialog>
  );
};

PandemiaDialog.defaultProps = {
  configs: {},
  open: false,
}

PandemiaDialog.prototype = {
  configs: PropsTypes.object,
  onClose: PropsTypes.func.isRequired,
  open: PropsTypes.bool,
}

export default PandemiaDialog;
