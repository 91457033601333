import React from 'react';
import PropsTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';

import { DialogContentText, Grid } from '@material-ui/core';
import Dialog from 'components/Dialog';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';

const CodeDialog = ({ onClose, open }) => {
  const data = useStoreState(({ subscription }) => subscription.data);

  const handleOpen = () => {
    const win = window.open(data?.prova, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        label="Resultado Dialog"
        title="Resultado"
        dark
      >
        <DialogContentText>
          <Grid
            container
            direction="column"
            alignItems="center"
          >
            <Text bold size={20} align="center">
              SUA INSCRIÇÃO FOI FEITA COM SUCESSO<br/>
            </Text>

            <Grid item container justify="center" sm={12} md={6} style={{ marginTop: 25, padding: 10 }}>
              <Styled.BtnBorder background={Theme.green.secondary} onClick={handleOpen} border style={{ width: '100%', padding: 15  }}>
                <Text bold size={14}>
                  FAZER MINHA PROVA AGORA
                </Text>
              </Styled.BtnBorder>
            </Grid>

            <Text align="center" style={{ marginTop: 25 }}>
              SE PREFERIR, USE O CÓDIGO ABAIXO PARA FAZER A PROVA POSTERIORMENTE, EM ATÉ 48 HORAS, UTILIZANDO O CÓDIGO ABAIXO:
            </Text>

            <Text size={36} bold align="center" style={{ marginTop: 25 }}>
              {data?.codigo}
            </Text>
          </Grid>
        </DialogContentText>
      </Dialog>
    </>
  );
};

CodeDialog.defaultProps = {
  open: false,
}

CodeDialog.prototype = {
  onClose: PropsTypes.func.isRequired,
  open: PropsTypes.bool,
}

export default CodeDialog;
