import React from 'react';
import PropTypes from 'prop-types';

import Theme from 'style/Theme';
import * as Styled from './styles';

const Subscribe = ({ onClick, marginTop, sm }) => (
  <Styled.Button
    background={Theme.green.primary}
    marginTop={marginTop}
    sm={sm}
    onClick={onClick}
  >
    <Styled.Title>
      INSCREVA-SE NESTE CURSO
    </Styled.Title>
  </Styled.Button>
);

Subscribe.defaultProps = {
  marginTop: 40,
  sm: '',
};

Subscribe.prototype = {
  marginTop: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  sm: PropTypes.string,
};

export default Subscribe;
