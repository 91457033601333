import { action, thunk } from 'easy-peasy';

import PhrasesAPI from 'api/Phrases';

export default {
  loading: false,

  phrases: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  list: thunk(async (actions, course) => {
    actions.setData({ type: 'loading', payload: true });
    actions.setData({ type: 'phrases', payload: [] });
  
    const response = await PhrasesAPI.get(course);
    if (response?.ok) {
      actions.setData({ type: 'phrases', payload: response.data });
    }

    actions.setData({ type: 'loading', payload: false });
  }),
};
