import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

import HomeCover from 'assets/images/home.png';

const CoverBackground = ({ cover, video }) => (
  <Styled.Container
    container
    direction="column"
    alignItems="center"
    cover={cover ? cover : HomeCover}
  >
    {video && (
      <video
        class="video-js"
        controls
        preload="auto"
        title="Uniessa Institucional - Curso"
        data-setup="{}"
        style={{ width: '100%', height: 500 }}
      >
        <source src={video} type="video/mp4" />
        <p class="vjs-no-js">
          Para visualizar esse video, você precisa habilitar o JavaScript e/ou atualizar o seu navegador.
        </p>
      </video>
    )}
  </Styled.Container>
);

CoverBackground.defaultProps = {
  cover: null,
  video: null,
}

CoverBackground.prototype = {
  cover: PropTypes.any,
  video: PropTypes.any,
}

export default CoverBackground;

