import React from 'react';
import PropsTypes from 'prop-types';

import { DialogContentText, Grid } from '@material-ui/core';
import Dialog from 'components/Dialog';
import RegisterForm from 'components/RegisterForm';

const RegisterDialog = ({ courseId, onClose, open }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        label="Inscreva-se Dialog"
        title="Inscreva-se agora"
        dark
      >
        <DialogContentText>
          <Grid container justify="center">
            <RegisterForm courseId={courseId} />
          </Grid>
        </DialogContentText>
      </Dialog>
    </>
  );
};

RegisterDialog.defaultProps = {
  courseId: null,
  open: false,
}

RegisterDialog.prototype = {
  courseId: PropsTypes.any,
  onClose: PropsTypes.func.isRequired,
  open: PropsTypes.bool,
}

export default RegisterDialog;
