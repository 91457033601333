import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Hidden } from '@material-ui/core';
import Text from 'components/Text';

import * as Styled from './styles'; 
import Theme from 'style/Theme';

const HelpCourse = ({ onClick, title, white }) => {
  return (
    <Styled.Container
      container
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <Hidden smDown>
          <Text
            color={Theme.white.primary}
            size={18}
            align="center"
            bold
          >
            {title}
          </Text>
        </Hidden>
        <Hidden mdUp>
          <Text
            color={Theme.white.primary}
            size={16}
            align="center"
          >
            {title}
          </Text>
        </Hidden>
      </Grid>

      <Grid item style={{ marginTop: 2 }}>
        <Styled.Button
          background={white ? Theme.white.primary : Theme.green.primary}
          onClick={onClick}
        >
          <>
            <Hidden mdUp>
                <Text
                  color={white ? Theme.black.primary : Theme.white.primary}
                  size={14}
                  align="center"
                >
                  Quero uma assessoria personalizada
                  <br/>
                  para escolher o meu curso
                </Text>
            </Hidden>
            <Hidden smDown>
              <Text
                color={white ? Theme.black.primary : Theme.white.primary}
                size={20}
                align="center"
              >
                Quero uma assessoria personalizada para
                <Text
                  color={white ? Theme.black.primary : Theme.white.primary}
                  size={20}
                  align="center"
                >
                  escolher meu curso!
                </Text>
              </Text>
            </Hidden>
          </>
        </Styled.Button>
      </Grid>
    </Styled.Container>
  );
};

HelpCourse.defaultProps = {
  white: false,
};

HelpCourse.prototype = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  white: PropTypes.bool,
};

export default HelpCourse;
