import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonTheme } from 'react-loading-skeleton';

import { Grid } from '@material-ui/core';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';

const Card = ({ onClick, featured, isLoading, icon, image, subtitle, title, titleAlign, titleInside, twoPerLine, smTitleAlign }) => (
  <Styled.Container
    item container
    xs={twoPerLine ? 6 : 12} sm={6} md={3}
    justify="center"
  >
    <Styled.Content
      item container
      alignItems="flex-start"
      justify="flex-start"
      direction="column"
      onClick={onClick}
    >
      {isLoading ? (
        <Styled.SkeletonContainer>
          <SkeletonTheme color="#202020" highlightColor="#444">
            <Styled.SkeletonContent height={200} width="100%" />
          </SkeletonTheme>
        </Styled.SkeletonContainer>
      ) : (
        <Styled.Image
          item container
          alignItems="flex-end"
          justify="flex-start"
          direction="column"
          image={image}
          icon={icon}
          alt={titleInside || title}
          name={titleInside || title}
        >
          {featured && <Styled.Featured item />}
          {titleInside && (
            <Grid
              container
              alignItems="flex-end"
              justify="center"
              style={{ height: '100%', flex: 1 }}
            >
              <Styled.Title color={Theme.white.primary} size={14} bold align={titleAlign} smAlign={smTitleAlign}>
                {`${titleInside.substring(0,28)}${titleInside.length > 28 ? '...': ''}`}
              </Styled.Title>
            </Grid>
          )}
        </Styled.Image>
      )}

      {title && (
        <Text size={16} style={{ marginTop: 10, width: '100%', marginBottom: 12 }} align={titleAlign} smAlign={smTitleAlign}>
          {`${title.substring(0,25)}${title.length > 25 ? '...': ''}`}
        </Text>
      )}

      {subtitle && (
        <Text color={Theme.gray.primary} style={{ marginTop: -8, width: '100%', }} align={titleAlign} smAlign={smTitleAlign}>
          {subtitle}
        </Text>
      )}
    </Styled.Content>
  </Styled.Container>
);

Card.defaultProps = {
  featured: false,
  isLoading: false,
  image: '',
  onClick: () => null,
  subtitle: '',
  title: '',
  titleAlign: 'center',
  titleInside: '',
  twoPerLine: false,
  smTitleAlign: 'center',
}

Card.prototype = {
  featured: PropTypes.bool,
  isLoading: PropTypes.bool,
  image: PropTypes.string,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  titleAlign: PropTypes.string,
  titleInside: PropTypes.string,
  twoPerLine: PropTypes.bool,
  smTitleAlign: PropTypes.string,
};

export default Card;
