import blog from './Blog';
import contact from './Contact';
import course from './Course';
import faq from './Faq';
import main from './Main';
import phrases from './Phrases';
import professor from './Professor';
import subscription from './Subscription';

export default {
  blog,
  contact,
  course,
  faq,
  main,
  phrases,
  professor,
  subscription
};