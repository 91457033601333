import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import ContactForm from 'components/ContactForm';
import Courses from 'components/Courses';
import WhatsappContact from 'components/WhatsappContact';
import BlogLastestPosts from './components/BlogLastestPosts';
import HelpCourse from './components/HelpCourse';
import Overview from './components/Overview';

import * as Styled from './styles';

const Home = () => {
  const [
    courses,
    loadingCourses,
  ] = useStoreState(({ course }) => [
    course.courses,
    course.loading,
  ]);

  const getPosts = useStoreActions(({ blog }) => blog.getPosts);

  useEffect(() => {
    getPosts();
  }, [0]);

  const goToContact = () => {
    const win = window.open('#contato', '_self');
    if (win != null) {
      win.focus();
    }
  }

  return (
    <Styled.Container>
      <Overview />

      <Styled.Content>
        <Courses
          loading={loadingCourses}
          list={courses}
          title="Escolha o seu curso de"
          subtitle="graduação presencial"
        />
      </Styled.Content>

      <Styled.ContentHelp>
        <HelpCourse
          onClick={goToContact}
          title="Está em dúvida sobre qual curso escolher? Clique no botão abaixo:"
        />
      </Styled.ContentHelp>

      <BlogLastestPosts />

      <WhatsappContact />

      <Styled.ContactFormContainer
        container
        justify="center"
      >
        <ContactForm />
      </Styled.ContactFormContainer>
    </Styled.Container>
  );
}

export default Home;
