import Styled from 'styled-components';
import { Grid, Button } from '@material-ui/core';
import Text from 'components/Text';

import HomeCover from 'assets/images/home.png';

export const Container = Styled(Grid)`
  background: linear-gradient(to top, rgba(6,6,6,1), rgba(0,0,0,0)), url(${HomeCover});
  flex: 1;
  min-height: 100vh;
  padding: 0px;
  padding-bottom: 50px;
  padding-top: 130px;
  position: relative;

  @media(max-width: 959px) {
    padding: 115px 25px 30px 30px;
  }
`;

export const ButtonContainer = Styled(Grid)`
  width: 100%;
`;

export const ButtonAlreadyHaveCode = Styled(Button)`
  background-color: rgba(0, 0, 0, 0.5);
  color: white !important;
  border: 2px solid white;
  font-size: 18px;
  height: 69px;
  padding: 42px 27px;
  text-transform: initial;

  @media (min-width: 701px) and (max-width: 959px) {
    padding: 42px 27px;
    width: 70%;
  }

  @media (max-width: 700px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    height: 95px;
    padding: 14px 28px;
    width: 90%;
  }
`

export const ButtonPandemia = Styled(Button)`
  background-color: rgba(0, 0, 0, 0.5);
  color: white !important;
  border: 2px solid white;
  font-size: 18px;
  height: 69px;
  margin-left: 35px;
  padding: 42px 27px;
  text-transform: initial;

  @media (min-width: 701px) and (max-width: 959px) {
    margin-left: 0px;
    margin-top: 15px;
    padding: 42px 27px;
    width: 70%;
  }

  @media (max-width: 700px) {
    font-size: 14px;
    font-weight: 600;
    height: 95px;
    line-height: 19px;
    margin-left: 0px;
    margin-top: 15px;
    padding: 6px 22px;
    width: 90%;
  }
`

export const VideoContainer = Styled(Grid)`
  flex: 1;
  overflow: hidden;
  width: 100%;
`;

export const TitleContainer = Styled(Grid)`
`;

export const Title = Styled(Text)`
  font-size: 32px;
  line-height: 41px;
  margin-top: 30px;
  max-width: 701px;
  margin-bottom: 30px;
  font-weight: bold;
  z-index: 1;
  text-shadow: 5px 3px 5px #23252b;
  opacity: 1;

  @media (max-width: 700px) {
    font-size: 22px !important;
    padding: 0 20px;
  }
`
