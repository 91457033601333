import React from 'react';
import PropsTypes from 'prop-types';

import { DialogContentText, Grid } from '@material-ui/core';
import Dialog from 'components/Dialog';
import Form from './components/Form';

const ScholarshipDialog = ({ courseId, onClose, open }) => {
  const submit = (response) => {
    onClose(true);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        label="Inscreva-se Dialog"
        title="Inscreva-se agora"
        dark
      >
        <DialogContentText>
          <Grid container justify="center">
            <Form onSubmit={submit} courseId={courseId} />
          </Grid>
        </DialogContentText>
      </Dialog>
    </>
  );
};

ScholarshipDialog.defaultProps = {
  courseId: '',
  open: false,
}

ScholarshipDialog.prototype = {
  courseId: PropsTypes.any,
  onClose: PropsTypes.func.isRequired,
  open: PropsTypes.bool,
}

export default ScholarshipDialog;
