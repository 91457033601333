import React from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import LogoUniessa from 'assets/svg/LogoUniessa.svg';
import MenuIcon from '@material-ui/icons/Menu';

import * as Styled from './styles';
import Text from 'components/Text';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function Header() {
  const classes = useStyles();

  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/');
    window.scrollTo(0, 0);
  }

  const openUrl = (url) => {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  const mobileMenuId = 'uniessa-header-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem alt="INSTAGRAM" onClick={() => openUrl('https://www.instagram.com/uniessaoficial')}>
        Instagram
      </MenuItem>
      <MenuItem alt="FACEBOOK" onClick={() => openUrl('https://www.facebook.com/Uniessa')}>
        Facebook
      </MenuItem>
      <MenuItem alt="YOUTUBE" onClick={() => openUrl('https://www.youtube.com/channel/UC_8una7ZiMjF76PFnQlmCbg')}>
        Youtube
      </MenuItem>
      <MenuItem alt="BLOG UNIESSA" onClick={() => openUrl('https://www.instagram.com')}>
        Blog Uniessa
      </MenuItem>
      <MenuItem alt="ÁREA DO ALUNO" onClick={() => openUrl('https://plataformauniessa.com.br/uniessa')}>
        <b>Área do Aluno</b>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <Styled.AppBar position="fixed">
        <Styled.Toolbar>
          <div onClick={handleBack} style={{ cursor: 'pointer' }}>
            <img
              name="Logo Uniessa"
              alt="Logo Uniessa"
              src={LogoUniessa}
              height={40}
            />
          </div>

          <div className={classes.sectionDesktop}>
            <Styled.Button
              color="inherit"
              alt="Instagram"
              href="https://www.instagram.com/uniessaoficial"
              target="_blank"
            >
              <Text weight={400}>Instagram</Text>
            </Styled.Button>
            <Styled.Button
              color="inherit"
              alt="Facebook"
              href="https://www.facebook.com/Uniessa"
              target="_blank"
            >
              <Text weight={400}>Facebook</Text>
            </Styled.Button>
            <Styled.Button
              color="inherit"
              alt="Youtube"
              href="https://www.youtube.com/channel/UC_8una7ZiMjF76PFnQlmCbg"
              target="_blank"
            >
              <Text weight={400}>Youtube</Text>
            </Styled.Button>
            <Styled.Button
              color="inherit"
              alt="BLOG UNIESSA"
              href="https://www.uniessa.com.br/category/blog"
              target="_blank"
            >
              <Text weight={400}>Blog da Uniessa</Text>
            </Styled.Button>

            <Styled.ButtonStudentArea
              color="inherit"
              alt="Área do Aluno"
              href="https://plataformauniessa.com.br/uniessa"
              target="_blank"
            >
              <Text>Área do Aluno</Text>
            </Styled.ButtonStudentArea>
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="Menu"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Styled.Toolbar>
      </Styled.AppBar>
      {renderMobileMenu}
    </div>
  );
}
