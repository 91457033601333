import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Text from 'components/Text';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import Theme from 'style/Theme';
import * as Styled from './styles';

const BackButtonInitial = ({ marginTop, sm }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
    window.scrollTo(0, 0);
  }

  return (
    <Styled.Container
      container
      justify="center"
      marginTop={marginTop}
      sm={sm}
    >
      <KeyboardBackspaceIcon
        style={{ color: Theme.green.primary, cursor: 'pointer' }}
        onClick={handleBack}
      />
      <div onClick={handleBack} style={{ marginLeft: 10, cursor: 'pointer' }}>
        <Text size={16}>
          Voltar para a página inicial
        </Text>
      </div>
    </Styled.Container>
  );
}

BackButtonInitial.defaultProps = {
  marginTop: 35,
  sm: '',
};

BackButtonInitial.prototype = {
  marginTop: PropTypes.number,
  sm: PropTypes.string,
};

export default BackButtonInitial;
