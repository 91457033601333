import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import InputBase from '@material-ui/core/Input';
import InputCustom from 'components/Form/Input';

export const Container = Styled(Grid)`
  background-color: ${({ theme }) => theme.white.primary};
  border-radius: 4px;
  height: auto;
  padding: 30px;

  @media (max-width: 959px) {
    background-color: white;
    padding: 20px;
    width: 100%;
    max-width: 800px;
  }
`;

export const Input = Styled(Field)`
  border-width: 0px;
  border-radius: 4px;
  font-family: 'OpenSansRegular'; 
  font-size: 16px;
  height: 20px;
  min-height: 20px;
  padding: 4%;
  width: 92%;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);

  :focus{
    outline: none;
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.15), 0px 4px 16px rgba(51, 51, 51, 0.3);
  }
  resize: vertical;
`;

export const InputMasked = Styled(InputBase)`
  background-color: #fff;
  border-width: 0px;
  border-radius: 4px;
  font-family: 'OpenSansRegular'; 
  font-size: 16px;
  height: 49px;
  min-height: 20px;
  padding: 4%;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);

  :focus{
    outline: none;
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.15), 0px 4px 16px rgba(51, 51, 51, 0.3);
  }
`;
