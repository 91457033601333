import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';

import ContactForm from 'components/ContactForm';
import CoverBackground from 'components/CoverBackground';
import Professors from 'components/Professors';
import RegisterDialog from 'components/RegisterDialog';
import Subscribe from 'components/Subscribe';
import WhatsappContact from 'components/WhatsappContact';
import BackButton from './components/BackButton';
import Overview from './components/Overview';

import * as Styled from './styles';

const Professor = () => {
  const { courseId, professorId } = useParams();

  const [
    courses,
    professor,
    professors,
  ] = useStoreState(({
    course,
    professor,
  }) => [
    course.courses,
    professor.professor,
    professor.professors,
  ]);

  const [
    getProfessor,
    listProfessor,
  ] = useStoreActions(({
    professor,
  }) => [
    professor.get,
    professor.list,
  ]);

  useEffect(() => {
    getProfessor(professorId);
    listProfessor(courseId);
  }, [0]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ flex: 1, width: '100%' }}>
      <RegisterDialog onClose={handleClose} open={open} courseId={courseId} />

      <CoverBackground cover={professor?.foto} />
      <Styled.Container
        container
        alignItems="center"
        direction="column"
      >
        <Overview professor={professor} />

        <Subscribe onClick={handleOpen} marginTop={40} />

        <BackButton course={courseId} />
      </Styled.Container>

      <Styled.ProfessorContainer>
        <Professors
          title={`CONHEÇA OUTROS PROFESSORES DO CURSO DE ${(courses.find((item) => item.id == courseId))?.apelido}`}
          list={professors.filter((item) => item.id != professorId)}
          limit={true}
          course={courseId}
        />
      </Styled.ProfessorContainer>

      <WhatsappContact />

      <Styled.ContactContainer container justify="center" id="contato">
        <ContactForm />
      </Styled.ContactContainer>
    </div>
  );
};

export default Professor;
