import React from 'react';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Grid } from '@material-ui/core';
import Text from 'components/Text';

import WrongIcon from 'assets/images/wrong.png';

import Theme from 'style/Theme';
import * as Styled from './styles';

const PriceCard = ({
  background,
  border,
  cursor,
  description,
  descriptionColor,
  loading,
  onClick,
  showIcon,
  title,
  titleColor,
}) => {
  if (loading) {
    return (
      <Grid
        item container
        xs={12} sm={12} md={4} lg={4}
        direction="column"
        justify="center"
        alignItems="center"
        style={{ padding: 15 }}
      >
        <SkeletonTheme color="#202020" highlightColor="#444" width="100%">
          <Skeleton height={250} width={250} />
        </SkeletonTheme>
      </Grid>
    );
  }

  return (
    <Grid
      item container
      xs={12} sm={12} md={4} lg={4}
      direction="column"
      justify="center"
      alignItems="center"
      style={{ padding: 15 }}
    >
      <Styled.Card
        item container
        direction="column"
        justify="center"
        alignItems="center"
        background={background}
        border={border}
        color={Theme.white.primary}
        onClick={onClick}
        cursor={cursor}
      >
        <Text
          align="center"
          size={18}
          color={titleColor}
        >
          {title}
        </Text>
        {showIcon && <Styled.Image src={WrongIcon} alt="Preço antigo" name="Preço antigo" />}
        {description && (
          <Text
            bold
            size={32}
            align="center"
            color={descriptionColor}
          >
            {description}
          </Text>
        )}
      </Styled.Card>
    </Grid>
  );
};

PriceCard.defaultProps = {
  background: Theme.black.primary,
  border: false,
  cursor: false,
  description: '',
  descriptionColor: Theme.green.primary,
  loading: false,
  onClick: () => null,
  showIcon: false,
  title: '',
  titleColor: Theme.black.primary,
};

PriceCard.prototype = {
  background: PropTypes.string,
  border: PropTypes.bool,
  cursor: PropTypes.bool,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  title: PropTypes.string,
  titleColor: PropTypes.string,
};

export default PriceCard;
