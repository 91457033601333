import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

export const Container = Styled(Grid)`
  flex: 1;
  margin-top: 60px;
  width: 100%;
`;

export const VideoContainer = Styled(Grid)`
  flex: 1;
  margin-top: 45px;
  width: 100%;
  max-width: 701px;

  @media (max-width: 959px) {
    width: 100%;
  }
`;

export const SkeletonContent = Styled(Skeleton)`
  height: 400px;
  width: 701px;

  @media (max-width: 959px) {
    height: 240px !important;
    width: 100% !important;
    min-width: 280px;
    max-width: 400px;
  }
`;
