import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Card = Styled(Grid)`
  background-color: ${({ background }) => background};
  border-radius: 10px;
  border: 1px solid ${({ border, theme }) => border ? theme.green.primary : 'transparent'};
  cursor: ${({ cursor }) => cursor ? 'pointer' : 'auto'};
  padding: 25px;
  height: 300px;
  width: 100%;
  max-width: 350px;

  @media (max-width: 959px) {
    height: 200px;
  }
`;

export const Image = Styled.img`
  height: 150px;
  position: absolute;
  opacity: 0.55;
`;
