import { create } from 'apisauce';

export const API_URL = 'https://homolog.plataformauniessa.com.br/homolog/ws';
//export const API_URL = 'https://plataformauniessa.com.br/uniessa/ws';

const API = create({
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json',
    'api-token': 'BcEbe-1cDd1-3Fc62-6C65a-78f28-3Y195',
  },
  timeout: 30000,
});

export default API;
