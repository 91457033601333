import Styled from 'styled-components';

export const Image = Styled.img`
  bottom: 15px;
  cursor: pointer;
  height: 45px;
  position: fixed;
  right: 15px;
  transition: height 1s;

  :hover {
    height: 55px;
  }
`;
