import { action, thunk } from 'easy-peasy';

import CourseAPI from 'api/Course';

export default {
  loading: false,
  loadingCourse: false,

  course: null,
  courses: [],

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  list: thunk(async (actions) => {
    actions.setData({ type: 'loading', payload: true });
  
    const response = await CourseAPI.list();
    if (response?.ok) {
      actions.setData({ type: 'courses', payload: response.data });
    }

    actions.setData({ type: 'loading', payload: false });
  }),

  get: thunk(async (actions, id) => {
    actions.setData({ type: 'course', payload: null });
    actions.setData({ type: 'loadingCourse', payload: true });
  
    const response = await CourseAPI.get(id);
    if (response?.ok) {
      actions.setData({ type: 'course', payload: response.data });
    }

    actions.setData({ type: 'loadingCourse', payload: false });
  }),
};
