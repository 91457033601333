import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import SubscriptionApi from 'api/Subscription';

export default {
  loading: false,
  loadingForContact: false,
  loadingForRegister: false,
  loadingForDiscount: false,

  partners: [],
  student: {},
  data: {},
  discount: {},

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  getPartners: thunk(async (actions) => {
    actions.setData({ type: 'loading', payload: true });

    actions.setData({ type: 'partners', payload: [] });
  
    const response = await SubscriptionApi.getPartners();
    if (response?.ok) {
      actions.setData({ type: 'partners', payload: response?.data });
    }

    actions.setData({ type: 'loading', payload: false });
  }),

  register: thunk(async (actions, payload) => {
    actions.setData({ type: 'loadingForRegister', payload: true });

    actions.setData({ type: 'data', payload: {} });
  
    const response = await SubscriptionApi.register(payload);
    if (!response?.ok || response?.data?.err) {
      let message = response?.data?.msg || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.'
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      actions.setData({ type: 'data', payload: response?.data });
    }

    actions.setData({ type: 'loadingForRegister', payload: false });

    return response?.ok && !response?.data?.err;
  }),

  registerForContact: thunk(async (actions, payload) => {
    actions.setData({ type: 'loadingForContact', payload: true });
  
    const response = await SubscriptionApi.registerForContact(payload);
    if (!response?.ok || response?.data?.err) {
      let message = response?.data?.msg || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.'
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    actions.setData({ type: 'loadingForContact', payload: false });

    return response?.ok && !response?.data?.err;
  }),

  registerForDiscount: thunk(async (actions, payload) => {
    actions.setData({ type: 'loadingForDiscount', payload: true });

    actions.setData({ type: 'student', payload: {} });
    actions.setData({ type: 'discount', payload: {} });
  
    const response = await SubscriptionApi.registerForDiscount(payload);

    if (!response?.ok || response?.data?.err) {
      let message = response?.data?.msg || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.'
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      actions.setData({ type: 'student', payload });
      actions.setData({ type: 'discount', payload: response?.data });
    }

    actions.setData({ type: 'loadingForDiscount', payload: false });

    return response?.ok && !response?.data?.err;
  }),

  registerForPresential: thunk(async (actions, payload) => {
    actions.setData({ type: 'loading', payload: true });
  
    const response = await SubscriptionApi.registerForPresential(payload);
    if (!response?.ok || response?.data?.err) {
      let message = response?.data?.msg || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.'
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    actions.setData({ type: 'loading', payload: false });

    return response?.ok && !response?.data?.err;
  }),

  sendCode: thunk(async (actions, payload) => {
    actions.setData({ type: 'loading', payload: true });
  
    const response = await SubscriptionApi.sendCode(payload);
    
    if (!response?.ok || response?.data?.err) {
      let message = response?.data?.msg || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.'
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const win = window.open(response?.data?.prova, '_blank');
      if (win) win.focus(); 
    }

    actions.setData({ type: 'loading', payload: false });

    return !!response?.data?.prova;
  }),

};
