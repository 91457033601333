import Styled from 'styled-components';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiButton from '@material-ui/core/Button';
import MuiToolbar from '@material-ui/core/Toolbar';

export const AppBar = Styled(MuiAppBar).attrs(({ theme }) => ({
  style: {
    boxShadow: 'none',
  }
})
)`
`;

export const Button = Styled(MuiButton)`
  margin-left: 18px;
  text-transform: initial;
`;

export const ButtonStudentArea = Styled(MuiButton)`
  background-color: ${({ theme }) => theme.green.primary} !important;
  border-radius: 4px;
  height: 40px;
  margin-left: 18px;
  padding: 5px 10px;
  text-transform: initial;
  width: 150px;
`;

export const Toolbar = Styled(MuiToolbar)`
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  z-index: 1;
  box-shadow: inset 0 74px 66px -28px rgb(13 14 16);
  padding-right: 8.7%;
  padding-left: 8.7%;
`;
