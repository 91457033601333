import React from 'react';
import PropsTypes from 'prop-types';

import { DialogContentText, Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from 'components/Dialog';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';

const ThanksDialog = ({ description, onClose, open }) => (
  <Dialog
    open={open}
    onClose={onClose}
    label="Obrigado por se inscrever Dialog"
    title=""
  >
    <DialogContentText>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ padding: '0 25px' }}
      >
        <Styled.IconContainer
          item container
          justify="center"
          alignItems="center"
        >
          <DoneIcon style={{ color: Theme.green.primary, fontSize: 36 }} />
        </Styled.IconContainer>
        <Text
          size={22}
          bold
          style={{ marginTop: 20 }}
          color={Theme.black.primary}
        >
          Mensagem enviada!
        </Text>
        <Text
          style={{ marginTop: 15 }}
          color={Theme.black.primary}
        >
          {description}
        </Text>

      </Grid>
    </DialogContentText>
  </Dialog>
);


ThanksDialog.defaultProps = {
  description: '',
  open: false,
};

ThanksDialog.prototype = {
  description: PropsTypes.string,
  onClose: PropsTypes.func.isRequired,
  open: PropsTypes.bool,
};

export default ThanksDialog;
