import { Grid } from '@material-ui/core';
import Styled from 'styled-components';

import HomeCover from 'assets/images/home.png';

export const Container = Styled.div`
  flex: 1;
`;

export const Content = Styled.div`
  flex: 1;
  padding: 20px 8%;
  margin-top: 0;
  background: linear-gradient(to top, #18191b, #18191b 25%, #18191b 75%, #090909 100%);

  @media (max-width: 959px) {
    padding: 0px 20px 10px 10px;
  }
`;

export const ContentHelp = Styled.div`
  flex: 1;
  padding-left: 8%;
  padding-right: 8%;
  background: #0D0D0E;
  padding-bottom: 75px;
  padding-top: 25px;
`;

export const ContainerWithBackground = Styled(Grid)`
  background: linear-gradient(to top, rgba(6,6,6,1), rgba(0,0,0,0)), url(${HomeCover});
  backgroud-size: cover;
  margin-top: 50px;
  padding-left: 8% !important;
  padding-right: 8% !important;
  width: 100%;
`;

export const ContactFormContainer = Styled(Grid)`
  background: linear-gradient(to top, #060606, #191C21 25%, #191C21 75%, #060606 100%);
  margin-top: 86px;
  padding: 0 8%;
  width: 100%;

  @media (max-width: 959px) {
    margin-top: 40px;
    padding: 0;
  }
`;
