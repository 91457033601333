import React from 'react';

import { Grid, Hidden } from '@material-ui/core';
import Text from 'components/Text';

import PhoneImage from 'assets/images/phone.png';

import openWhatsapp from 'services/Whatsapp';

import Theme from 'style/Theme';
import * as Styled from './styles';

const WhatsappContact = () => (
  <Grid
    container
    alignItems="center"
    justify="center"
    direction="row"
  >
    <Styled.Container
      container
      alignItems="stretch"
      justify="center"
    >
      <Styled.LeftContainer
        sm={12}
        md={7}
        item
        container
        alignItems="center"
        justify="center"
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          style={{ padding: 0 }}
        >
          <Styled.ContainerWithBackground
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Grid item>
              <Text
                size={17}
                bold
                align="center">
                Tem alguma dúvida sobre o vestibular,
                <br/>
                sobre a faculdade ou qual curso escolher?
              </Text>
            </Grid>

            <Grid item>
              <Text
                size={14}
                align="center"
                style={{ margin: '16px 0' }}
              >
                Fale com a gente pelo WhatsApp.
              </Text>
            </Grid>

            <Grid item>
              <Styled.Button
                rounded="true"
                background={Theme.white.primary}
                onClick={openWhatsapp}
              >
                <Text
                  bold
                  color={Theme.black.primary}
                  align="center"
                  size={12}
                >
                  FALE COM A UNIESSA
                </Text>
              </Styled.Button>
            </Grid>
          </Styled.ContainerWithBackground>
        </Grid>
      </Styled.LeftContainer>

      <Hidden smDown>
        <Styled.RightContainer
          md={5}
          item
          container
          alignItems="flex-start"
          justify="center"
        >
          <Styled.Image src={PhoneImage} alt="Telefone" name="Telefone" />
        </Styled.RightContainer>
      </Hidden>
    </Styled.Container>
  </Grid>
);

export default WhatsappContact;
