import React from 'react';
import ElasticCarousel from 'react-elastic-carousel';
import { useStoreState } from 'easy-peasy';
import { SkeletonTheme } from 'react-loading-skeleton';

import { Grid, Hidden } from '@material-ui/core';

import * as Styled from './styles';

const Carousel = () => {
  const [loading, posts] = useStoreState(({ blog }) => [blog.loading, blog.posts]);

  const openPost = (url) => {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  const renderItem = (item, index) => (
    <Styled.ItemContainer
      item
      container
      direction="column"
      alignItems="center"
      justify="center"
      key={`${item?.id}_${index}`}
      onClick={() => openPost(item?.link)}
    >
      <Styled.Image src={item?.media} alt={item?.title?.rendered} name={item?.title?.rendered} />
      <Styled.Text size={12}>
        {item?.title?.rendered}
      </Styled.Text>
    </Styled.ItemContainer>
  );

  const renderPagination = ({ pages, activePage, onClick }) => (
    <Styled.Pagination
      container
      justify="center"
      alignItems="center"
    >
      {pages.map((page, index) => (
        <Grid
          item
          onClick={() => onClick(page)}
          key={`${index}`}
        >
          {page === activePage ? <Styled.CheckedIcon/> : <Styled.UncheckedIcon/>}
        </Grid>
      ))}
    </Styled.Pagination>
  )

  if (loading) {
    return (
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item container justify="center" xs={12} sm={12} md={6}>
          <Styled.SkeletonContainer>
            <SkeletonTheme color="#202020" highlightColor="#444">
              <Styled.SkeletonContent height={350} width="100%" />
            </SkeletonTheme>
          </Styled.SkeletonContainer>
        </Grid>
        <Hidden smDown>
          <Grid item container justify="center" xs={12} sm={12} md={6}>
            <Styled.SkeletonContainer>
              <SkeletonTheme color="#202020" highlightColor="#444">
                <Styled.SkeletonContent height={350} width="100%" />
              </SkeletonTheme>
            </Styled.SkeletonContainer>
          </Grid>
        </Hidden>
      </Grid>
    );
  }

  return (
    <>
      <Hidden smDown>
        <ElasticCarousel
          itemsToShow={2}
          itemsToScroll={2}
          showArrows={false}
          enableSwipe
          enableMouseSwipe
          enableAutoPlay={false}
          autoPlaySpeed={7000}
          renderPagination={renderPagination}
        >
          {posts.map((item, index) => renderItem(item, index))}
        </ElasticCarousel>
      </Hidden>
      <Hidden mdUp>
        <ElasticCarousel
          itemsToShow={1}
          itemsToScroll={1}
          showArrows={false}
          enableSwipe
          enableMouseSwipe
          enableAutoPlay
          autoPlaySpeed={7000}
          renderPagination={renderPagination}
        >
          {posts.map((item, index) => renderItem(item, index))}
        </ElasticCarousel>
      </Hidden>
    </>

  );
}

export default Carousel;
