import {
  action,
  thunk
} from 'easy-peasy';

import BlogAPI from 'api/Blog';

export default {
  loading: false,
  posts: [],

  setData: action((state, {
    type,
    payload
  }) => {
    state[type] = payload;
  }),

  setPosts: action((state, payload) => {
    state.posts = [...state.posts, payload];
  }),

  getPosts: thunk(async (actions) => {
    actions.setData({
      type: 'loading',
      payload: true
    });

    const response = await BlogAPI.getPosts();
    if (response?.ok) {
      let posts = response?.data?.slice(0, 4);
      for (let i = 0; i < posts.length; i++) {
        let item = posts[i];

        let responseImage = await BlogAPI.getImage(item?.featured_media);
        actions.setPosts({
          ...item,
          media: responseImage.ok ? responseImage?.data?.source_url : 'assets/images/errorImage.jpg',
        });
      }
    }

    actions.setData({
      type: 'loading',
      payload: false
    });
  }),
};