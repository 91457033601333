import React from 'react';
import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';

import { Grid } from '@material-ui/core';
import Card from 'components/Card';
import Text from 'components/Text';

import * as Styled from './styles';

const Professors = ({ course, list, title, limit }) => {
  const loading = useStoreState(({ professor }) => professor.loading);

  const handleClick = (professor) => {
    const win = window.open(`/curso/${course}/professor/${professor?.id}`, '_self');
    if (win != null) {
      win.focus();
    }
  };

  const adjustTitle = (professor) => {
    if(professor != null && professor.titulacao != undefined){
      return professor.titulacao.charAt(0).toUpperCase() + professor.titulacao.slice(1).toLowerCase();
    }
    return '';
  };

  const renderList = () => {
    if (loading) {
      return (
        <Grid container alignItems="center" justify="center">
          <Styled.ListContainer container justify="flex-start" wrap="wrap">
            {[1, 2, 3, 4].map((item, index) => (
              <Card
                key={`${index}`}
                isLoading
                hasTitle
                onClick={() => null}
                twoPerLine
              />
            ))}
          </Styled.ListContainer>
        </Grid>
      );
    }
    
    if (!list && !list.length) {
      return (
        <Styled.ListContainer container justify="center">
          <Text>Falha no carregamento dos professores, atualize a página e tente novamente.</Text>
        </Styled.ListContainer>
      )
    }

    return (
      <Grid container alignItems="center" justify="center">
        <Styled.ListContainer item container justify="flex-start" wrap="wrap">
          {list.map((item, index) => (!limit || index < 4) ?
            (
              <Card
                key={`${index}_${item?.id}`}
                featured={item?.coordenacao === true || item?.coordenacao === 'true'}
                title={item?.professor}
                subtitle={adjustTitle(item)}   
                image={item?.fotoAvatar}
                onClick={() => handleClick(item)}
                titleAlign="left"
                smTitleAlign="center"
                twoPerLine
              />
            ) : null
          )}
        </Styled.ListContainer>
      </Grid>
    );
  };

  return (
    <Styled.Container container direction="column" alignItems="center" justify="center">
      <Styled.Title
        align="center"
        bold
        transform="uppercase"
      >
        {title}
      </Styled.Title>

      {renderList()}
    </Styled.Container>
  );
};

Professors.defaultProps = {
  list: [],
  title: 'CLIQUE E CONHEÇA OS SEUS PROFESSORES',
}

Professors.prototype = {
  course: PropTypes.any.isRequired,
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
};

export default Professors;
