import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import CoverBackground from 'components/CoverBackground';
import Professors from 'components/Professors';
import Subscribe from 'components/Subscribe';
import WhatsappContact from 'components/WhatsappContact';
import RegisterForm from 'components/RegisterForm';
import RegisterDialog from 'components/RegisterDialog';
import Text from 'components/Text';
import BackButtonInitial from './components/BackButtonInitial';
import FAQ from './components/FAQ';
import Price from './components/Price';
import Review from './components/Review';
import Overview from './components/Overview';

import * as Styled from './styles';

const Course = () => {
  const { courseId } = useParams();

  const [
    course,
    professors,
  ] = useStoreState(({
    course,
    professor,
  }) => [
    course.course,
    professor.professors,
  ]);

  const [
    getCourse,
    listFaq,
    listPhrases,
    listProfessor,
  ] = useStoreActions(({
    course,
    faq,
    phrases,
    professor,
  }) => [
    course.get,
    faq.list,
    phrases.list,
    professor.list,
  ]);

  useEffect(() => {
    getCourse(courseId);
    listFaq(courseId);
    listPhrases(courseId);
    listProfessor(courseId);
  }, [0]);
  
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ flex: 1, width: '100%' }}>
      <RegisterDialog onClose={handleClose} open={open} courseId={courseId} />

      <CoverBackground cover={course?.fotoCurso} />
      <Styled.Container
        container
        alignItems="center"
        direction="column"
      >
        <Styled.Title bold transform="uppercase" align="center">
          {course?.nomeHistoricoEscolar || (
            <SkeletonTheme color="#202020" highlightColor="#444">
              <Skeleton height={50} style={{ width: '100%', minWidth: 250, maxWidth: 320 }} />
            </SkeletonTheme>
          )}
        </Styled.Title>
        <Text size={22}>Graduação Presencial</Text>

        <Overview course={course} />

        {/* <BackButton course={courseId} /> */}

        {/* <Text
          bold
          size={32}
          transform="uppercase"
        >
          {course?.nomeHistoricoEscolar || (
            <SkeletonTheme color="#202020" highlightColor="#444">
              <Skeleton height={50} width={350} />
            </SkeletonTheme>
          )}
        </Text>
        <Text
          size={22}
          transform="capitalize"
        >
          {course?.modalidade ? `Graduação ${course?.modalidade}` : (
            <SkeletonTheme color="#202020" highlightColor="#444">
              <Skeleton height={30} width={250} />
            </SkeletonTheme>
          )}
        </Text> */}

        <Subscribe onClick={handleOpen} marginTop={40} sm="margin-top: 0px;" />

        <BackButtonInitial sm="display: none;" />
      </Styled.Container>

      <Styled.ContainerProfessors>
        <Professors
          title="CLIQUE E CONHEÇA OS SEUS PROFESSORES"
          list={professors}
          course={courseId}
          courseTitle={course?.nomeHistoricoEscolar}
        />
      </Styled.ContainerProfessors>

      <Styled.ContainerBottom
        container
        alignItems="center"
        direction="column"
      >
        <Subscribe onClick={handleOpen} marginTop={5} />

        <BackButtonInitial />
        
        <Review video={course?.videoDepoimento} />

        <Subscribe onClick={handleOpen} marginTop={35} />

        <BackButtonInitial />
      </Styled.ContainerBottom>

      <Styled.ContainerWithBackground
        container
        direction="column"
        alignItems="center"
      >
        <Price />

        <FAQ />

        <Subscribe onClick={handleOpen} marginTop={40} />

        <BackButtonInitial />

        <Styled.WhatsappContainer>
          <WhatsappContact />
        </Styled.WhatsappContainer>

        <Styled.RegisterContainer>
          <RegisterForm courseId={courseId} />
        </Styled.RegisterContainer>
      </Styled.ContainerWithBackground>
    </div>
  );
}

export default Course;
