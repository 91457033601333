import { Grid } from '@material-ui/core';
import Styled from 'styled-components';

export const Container = Styled(Grid)`
  margin-top: 50px;
  padding-bottom: 0px;
  width: 100%;
`;

export const Content = Styled(Grid)`
  margin-top: 30px;
  max-width: 1000px;
`;
