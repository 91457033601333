import React, { useEffect } from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Grid } from '@material-ui/core';

import Course from 'containers/Course';
import Home from 'containers/Home';
import Professor from 'containers/Professor';

import Header from 'components/Header';
import Footer from 'components/Footer';
import WhatsappButton from 'components/WhatsappButton';

import Theme from 'style/Theme';

export default function MainRoutes() {
  const [getCourses, getConfig] = useStoreActions(({ course, main }) => [course.list, main.getConfig]);

  useEffect(() => {
    getConfig();
    getCourses();
  }, [0]);

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      style={{ flex: 1, backgroundColor: '#000' }}
    >
      <div style={{ flex: 1, width: '100%', backgroundColor: Theme.background }}>
        <Header />
        <Routes>
          <Route path="/curso/:courseId" element={<Course />} />
          <Route path="/curso/:courseId/professor/:professorId" element={<Professor />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
        <WhatsappButton />
      </div>
    </Grid>
  );
}
