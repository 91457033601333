import Styled from 'styled-components';
import { Form } from 'formik';
import { Grid } from '@material-ui/core';
import Text from 'components/Text';

export const Container = Styled(Grid)`
  padding: 0 25px;

  @media(max-width: 965px) {
    padding: 0;
    padding-top: 20px;
  }
`;

export const FormikForm = Styled(Form)`
  margin-top: 25px;
  width: 100%;
`;

export const Title = Styled(Text)`
  @media (max-width: 959px) {
    font-size: 14px;
  }
`
