import { action, thunk } from 'easy-peasy';
import { toast } from 'react-toastify';

import ContactAPI from 'api/Contact';

export default {
  loading: false,

  setData: action((state, { type, payload }) => {
    state[type] = payload;
  }),

  send: thunk(async (actions, course) => {
    actions.setData({ type: 'loading', payload: true });
  
    const response = await ContactAPI.send(course);
    if (!response?.ok || response?.data?.err) {
      let message = response?.data?.msg || 'Ocorre um erro inesperado, verifique sua conexão e tente novamente.'
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    actions.setData({ type: 'loading', payload: false });

    return response?.ok && !response?.data?.err;
  }),
};
