import React from 'react';
import * as Styled from './styles';

const videoSource = "https://s3-sa-east-1.amazonaws.com/uniessa.com.br/uploads/videos/video.mp4";

const BackgroundVideo = () => (
  <Styled.Container>
    <Styled.Video autoPlay="autoplay" autobuffer autoplay playsinline loop="loop" muted>
      <source src={videoSource} type="video/mp4" />
      Your browser does not support the video tag.
    </Styled.Video>

    <Styled.Content />
  </Styled.Container>
);

export default BackgroundVideo;

