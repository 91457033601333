import Styled from 'styled-components';
import { Form } from 'formik';
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import Text from 'components/Text';

export const Container = Styled(Grid)`
  width: 100%;
`;

export const BtnBorder = Styled(Button)`
  border: 1px solid ${({ theme }) => theme.green.primary};
  border-radius: 8px;
  margin-right: 14px;
  width: 100%;

  @media (max-width: 959px) {
    margin-right: 0px;
  }
`;

export const ButtonSubmitContainer = Styled(Button)`
  margin-left: 14px;
  width: 100%;
  
  @media (max-width: 959px) {
    margin-left: 0px;
    margin-top: 15px;
  }
`;

export const AlderadyRegistered = Styled(Text)`
  cursor: pointer;
  margin-top: 47px;
  text-decoration: underline;
  width: 100%;

  @media (max-width: 959px) {
    margin-bottom: 10px;
  }
`;

export const FormikForm = Styled(Form)`
  margin-top: 25px;
  width: 100%;
`;
