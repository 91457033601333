import Styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = Styled(Grid)`
  margin-top: ${({ marginTop }) => marginTop || 35}px;

  @media (max-width: 959px) {
    ${({ sm }) => sm || ''};
  }
`;
