import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import Text from 'components/Text';

import LogoUniessa from 'assets/svg/LogoUniessa.svg';

import Theme from 'style/Theme';
import * as Styled from './styles';

const useStyles = makeStyles((theme) => ({
  alignMobile: {
    alignItems: 'flex-start',
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      marginTop: 0,
    },
  },
  textMargin: {
    marginTop: 5,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  secondColumnMargin: {
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
}));

export default function Header() {
  const classes = useStyles();

  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/');
    window.scrollTo(0, 0);
  }

  const configs = useStoreState(({ main }) => main.configs);

  return (
    <Styled.Container
      container
      direction="column"
      alignItems="center"
    >
      <Grid item container direction="row" style={{ padding: '0 3%' }}>
        <Grid
          item container
          direction="column"
          xs={12} sm={12} md={5}
          className={classes.alignMobile}
        >
          <Grid item>
            <div onClick={handleBack} style={{ cursor: 'pointer', marginTop: 20 }}>
              <img
                name="Logo Uniessa"
                alt="Logo Uniessa"
                src={LogoUniessa}
                height={56}
              />
            </div>
          </Grid>
          <Grid item style={{ marginTop: 24, width: '100%' }}>
            <Hidden smDown>
              <Text size={13} weight={400} height={19} align="left">
                Av. das Américas, 505 - Morada da Colina,<br />
                Uberlândia MG, 38411-020 | CNPJ: <br />
                21.289.889/0028-69
              </Text>
            </Hidden>
            <Hidden mdUp>
              <Text size={13} weight={400} height={19} align="center">
                Av. das Américas, 505 - Morada da Colina,<br />
                Uberlândia MG, 38411-020 | CNPJ: <br />
                21.289.889/0028-69
              </Text>
            </Hidden>
          </Grid>
        </Grid>

        <Grid
          item container
          direction="column"
          xs={12} sm={12} md={5}
          className={classes.alignMobile}
          style={{ marginTop: 20, width: '100%' }}
        >
          <Grid item>
            <Hidden smDown>
              <Text bold>
                DOCUMENTOS DA UNIESSA
              </Text>
            </Hidden>
            <Hidden mdUp>
              <Text bold size={22} style={{ marginTop: 20 }}>
                DOCUMENTOS DA UNIESSA
              </Text>
            </Hidden>
          </Grid>
          <Grid item style={{ marginTop: 20, width: '100%' }}>
            <Styled.Link href={configs?.QUEM_INDICA} alt='Leia o Regulamento do "Quem Indica Amigo É"'>
              <Text size={13} color={Theme.green.primary} weight={400} height={26}>
                Leia o Regulamento do "Quem Indica Amigo É"
              </Text>
            </Styled.Link>
          </Grid>
          <Grid item className={classes.textMargin}>
            <Styled.Link href={configs?.QUEM_INDICA_DUVIDAS} alt='Dúvidas Frequentes sobre o "Quem Indica Amigo É"'>
              <Text size={13} weight={400} height={26}>
                Dúvidas Frequentes sobre o "Quem Indica Amigo É"
              </Text>
            </Styled.Link>
          </Grid>
          <Grid item className={classes.textMargin}>
            <Styled.Link href={configs?.CONTRATO_PRESTACAO} alt='Contratos de Prestação de Serviços'>
              <Text size={13} weight={400} height={26}>
                Contratos de Prestação de Serviços
              </Text>
            </Styled.Link>
          </Grid>
          <Grid item className={classes.textMargin}>
            <Styled.Link href={configs?.REG_BOLSAS_TRANSF} alt='Regulamento das Bolsas de Transferências'>
              <Text size={13} weight={400} height={26}>
                Regulamento das Bolsas de Transferências
              </Text>
            </Styled.Link>
          </Grid>
        </Grid>

        <Grid
          item container
          direction="column"
          xs={12} sm={12} md={2}
          className={classes.alignMobile}
        >
          <Grid item className={classes.textMargin}>
            <Styled.Link href={configs?.EDITAL_VEST} alt='Edital do Vestibular 2020-2'>
              <Text size={13} weight={400} height={26}>
                Edital do Vestibular 2020-2
              </Text>
            </Styled.Link>
          </Grid>
          <Grid item className={classes.textMargin}>
            <Styled.Link href={configs?.ENEM} alt='ENEM 2019'>
              <Text size={13} weight={400} height={26}>
                ENEM 2019
              </Text>
            </Styled.Link>
          </Grid>
          <Grid item className={classes.textMargin}>
            <Styled.Link href="https://jobs.solides.com/institutopater" alt='Trabalhe Conosco'>
              <Text size={13} weight={400} height={26}>
                Trabalhe Conosco
              </Text>
            </Styled.Link>
          </Grid>
        </Grid>
      </Grid>

      <Styled.Divider />

      <Text size={13} color={Theme.white.primary} style={{ margin: '28px 0' }} align="center">
        Copyright &copy; 2020 Faculdade Uniessa.&nbsp;
        <Hidden mdUp>
          <br/>
        </Hidden>
        Todos os direitos reservados.
      </Text>
    </Styled.Container>
  );
}
