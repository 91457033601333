import React from 'react';

import openWhatsapp from 'services/Whatsapp';

import WhatsappIcon from 'assets/images/whatsapp.png';

import * as Styled from './styles';

const WhatsappButton = () => (
  <Styled.Image src={WhatsappIcon} onClick={openWhatsapp} alt="Entre em contato pelo WhatsApp" name="WhatsApp" />
);

export default WhatsappButton;
