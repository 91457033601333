import React from 'react';
import PropsTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';

import { DialogContentText, Grid } from '@material-ui/core';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import PriceCard from 'components/PriceCard';
import Text from 'components/Text';

import Theme from 'style/Theme';
import * as Styled from './styles';

const ResultDialog = ({ course, onClose, open }) => {
  const data = useStoreState(({ subscription }) => subscription.discount);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        label="Resultado Dialog"
        title="Resultado"
        dark
        customSize
      >
        <DialogContentText>
          <Grid
            container
            direction="column"
            alignItems="center"
          >
            <Text bold size={22} align="center">
              RESULTADO DA AVALIAÇÃO DE BOLSA DE ESTUDO
            </Text>
            <Text size={18} align="center" style={{ marginTop: 25 }}>
              De acordo com o seu perfil, verificamos que você tem direito à seguinte bolsa de estudo:
            </Text>

            <Grid
              item container
              justify="center"
              style={{ marginTop: 30 }}
            >
              <PriceCard
                background={Theme.black.primary}
                description={`R$ ${course?.valorMesalidade}`}
                showIcon
                descriptionColor={Theme.white.primary}
                title="VALOR DA MENSALIDADE"
                titleColor={Theme.white.primary}
              />
              <PriceCard
                background={Theme.white.primary}
                description={`R$ ${course?.valorLiquido}`}
                showIcon
                descriptionColor={Theme.green.primary}
                title="VALOR DA MENSALIDADE COM DESCONTO"
                titleColor={Theme.black.primary}
              />
              <PriceCard
                background="transparent"
                border
                description={`R$ ${data.valor}`}
                title="VALOR DA MENSALIDADE COM A SUA BOLSA DE ESTUDO"
                titleColor={Theme.white.primary}
              />
            </Grid>

            <Text size={18} align="center" style={{ marginTop: 25 }}>
              O número de bolsas é limitado. Para garantir este valor, faça sua inscrição agora mesmo.
            </Text>
            <Text align="center" style={{ marginTop: 15 }}>
              Se você acredita que cumpre os requisitos para uma bolsa maior, solicite uma avaliação presencial com a nossa equipe de assistentes sociais.
            </Text>

            <Grid container justify="center" style={{ marginTop: 30 }}>
              <Grid item container justify="center" sm={12} md={6} style={{ padding: 10 }}>
                <Styled.BtnBorder background="transparent" onClick={() => onClose('THANKS')} style={{ width: '100%' }}>
                  <Text bold size={14} style={{ padding: 10, }}>
                    SOLICITAR UMA AVALIAÇÃO PRESENCIAL
                  </Text>
                </Styled.BtnBorder>
              </Grid>
              
              <Grid item container justify="center" sm={12} md={6} style={{ padding: 10 }}>
                <Button background={Theme.green.secondary} onClick={() => onClose('SUBSCRIBE')} border style={{ width: '100%' }}>
                  <Text color={Theme.black.primary} bold size={14} style={{ padding: 10, }}>
                    FAZER MINHA INSCRIÇÃO
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </Dialog>
    </>
  );
};

ResultDialog.defaultProps = {
  open: false,
}

ResultDialog.prototype = {
  course: PropsTypes.object.isRequired,
  onClose: PropsTypes.func.isRequired,
  open: PropsTypes.bool,
}

export default ResultDialog;
